// @ts-nocheck
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";
import { isImmutable, Map } from "immutable";
import { Corner } from "../../../entities";

function EditCornerPopupHeader(props) {
  const { setView, animation, setPosition } = props;

  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <div className="edit-popup__header-tabs">
        <h2
          className="edit-popup__heading"
          onClick={() => {
            setView("controls");
          }}
        >
          Edit Corner
        </h2>
      </div>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function EditCornerControls(props) {
  let { corner, dispatch } = props;

  if (!isImmutable(corner)) {
    corner = Corner(corner).set("id", Map(corner.id));
  }

  const type = corner.type ? corner.type : "double";

  const doubleClass =
    type === "double"
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";

  const singleClass =
    type === "single"
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";

  const cableContinuesThrough = corner.cableContinuesThrough
    ? corner.cableContinuesThrough
    : false;

  const cableContinuesThroughTrueClass =
    cableContinuesThrough === true
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";

  const cableContinuesThroughFalseClass =
    cableContinuesThrough === false
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";

  const insideCorner = typeof corner.insideCorner !== 'undefined' ? corner.insideCorner : true;
  const insideCornerClass = 
    insideCorner === true
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";
  
  const outsideCornerClass =
    insideCorner === false
      ? "edit-popup__three-button edit-popup__three-button--active"
      : "edit-popup__three-button";
  return (
    <div>
      <div className="edit-popup__container run-controls">
        <div className="edit-popup__section">
          <div className="edit-popup__control">
            <p className="edit-popup__label">Corner Type</p>
            <div className="edit-popup__three-buttons">
              <button
                onClick={() => {
                  if (corner.type !== "double") {
                    const newCorner = corner.set("type", "double");

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={doubleClass}
              >
                Double Post
              </button>
              <button
                onClick={() => {
                  if (corner.type !== "single") {
                    const newCorner = corner.set("type", "single");

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={singleClass}
              >
                Single Post
              </button>
            </div>
          </div>
          <div className="edit-popup__control">
            <p className="edit-popup__label">Inside/Outside Corner</p>
            <div className="edit-popup__three-buttons">
              <button
                onClick={() => {
                  if (insideCorner !== true) {
                    const newCorner = corner.set("insideCorner", true);

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={insideCornerClass}
              >
                Inside
              </button>
              <button
                onClick={() => {
                  if (insideCorner !== false) {
                    const newCorner = corner.set("insideCorner", false);

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={outsideCornerClass}
              >
                Outside
              </button>
            </div>
          </div>
          <div className="edit-popup__control">
            <p className="edit-popup__label">Cable Continues Through</p>
            <div className="edit-popup__three-buttons">
              <button
                onClick={() => {
                  if (corner.cableContinuesThrough !== false) {
                    const newCorner = corner.set(
                      "cableContinuesThrough",
                      false
                    );

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={cableContinuesThroughFalseClass}
              >
                Does Not Continue
              </button>
              <button
                onClick={() => {
                  if (corner.cableContinuesThrough !== true) {
                    const newCorner = corner.set("cableContinuesThrough", true);

                    dispatch({ type: "corners/edit", corner: newCorner });
                  }
                }}
                className={cableContinuesThroughTrueClass}
              >
                Continues
              </button>
            </div>
          </div>
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label">Post Type Controls</p>
            <div className="labels-section">
              <div className="labels-section__buttons cable-buttons">
                <button
                  className="labels-section__button"
                  onClick={() => {
                    dispatch({
                      type: "corners/edit-post-type",
                      postType: "terminal",
                      corner: corner,
                    });
                  }}
                >
                  Set Corner Posts to Terminal
                </button>
                <button
                  className="labels-section__button"
                  onClick={() => {
                    dispatch({
                      type: "corners/edit-post-type",
                      postType: "intermediate",
                      corner: corner,
                    });
                  }}
                >
                  Set Corner Posts to Intermediate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditCornerPopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const corner = props.edit.object;
  const [view, setView] = useState("controls");

  const views = {
    controls: EditCornerControls,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditCornerPopupHeader
        setView={setView}
        setPosition={setPosition}
        animation={animation}
      />
      <EditComponent corner={corner} dispatch={dispatch} />
    </div>
  );
}

export default EditCornerPopup;
