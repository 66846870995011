// @ts-nocheck
import { useState, useRef, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";

import IconButton from "./IconButton";
import RevisionsMenu from "./RevisionsMenu";
import { ProjectRevision, ProjectRevisionState } from "../entities";
import { getDateString } from "../utils";
import ExportPdf from "./ExportPDF/exportpdf";
import ExportSalesOrderPdf from "./ExportSalesOrder/ExportSalesOrderPDF";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { InventoryContext } from "../App";

function updateTool(tool, currentTool) {
  if (currentTool === tool) {
    return null;
  } else {
    return tool;
  }
}

const defaultSidebarClick = (dispatch) => () => {
  setTimeout(function () {
    dispatch({ type: "edit-popup/close" });
  }, 0);
};

function Options(props) {
  const project = useSelector((state) => state.state.present);
  const { dispatch, setOptionsOpen, setRevisionsOpen } = props;
  const inventory = useContext(InventoryContext);

  const ref = useRef();
  const clickOutside = useCallback(
    () => setOptionsOpen(false),
    [setOptionsOpen]
  );

  useOnClickOutside(ref, clickOutside);

  const navigate = useNavigate();

  return (
    <div ref={ref} className="app__options">
      <button
        onClick={() => {
          dispatch({ type: "app/projects-view" });
          navigate("/");
        }}
        className="options__top-link options__link"
      >
        Back to Saved Projects
      </button>
      <div className="options__middle">
        <ExportPdf />
        {project.isSalesOrder && <ExportSalesOrderPdf />}
        <button className="options__link">Save Project</button>
        <button
          onClick={() => {
            setRevisionsOpen((open) => true);

            setTimeout(function () {
              setOptionsOpen((open) => false);
            }, 0);
          }}
          className="options__link"
        >
          View Project Revisions
        </button>
        <button
          onClick={() => {
            if (project.frozenEstimate.id) {
              dispatch({ type: "project/unfreeze-estimate", project });
            } else {
              dispatch({ type: "project/freeze-estimate", inventory });
            }
          }}
          className="options__link"
        >
          {project.frozenEstimate.id ? "Unfreeze Estimate" : "Freeze Estimate"}
        </button>
      </div>
      <button
        onClick={() => {
          dispatch({ type: "settings/toggle" });
          setOptionsOpen((prevState) => !prevState);
        }}
        className="options__bottom-link options__link"
      >
        Project Settings
      </button>
    </div>
  );
}

function createRevision(project, description = "") {
  const currentDate = new Date();
  const datetime = getDateString(currentDate);
  const id = uuid();

  return ProjectRevision({
    date: currentDate,
    datetime,
    id,
    description,
    state: ProjectRevisionState(project),
  });
}

function RevisionsDescription(props) {
  const { dispatch, setRevisionsOpen } = props;
  const project = useSelector((state) => state.state.present);
  const projectName = project.settings.projectTitle;

  const [text, setText] = useState("");

  return (
    <div className="revisions-description__container">
      <div className="revisions-description__content">
        <p className="revisions-description__message">
          <span className="revisions-description__project-name">
            {projectName}
          </span>{" "}
          is currently being saved. Would you like to add a description to your
          revision?
        </p>
        <textarea
          className="revisisons-description__textarea"
          onChange={(event) => {
            setText(event.target.value);
          }}
          value={text}
        />
      </div>
      <div className="revisions-description__footer">
        <button
          onClick={() => {
            const revision = createRevision(project);

            dispatch({ type: "revisions/add", revision });

            setTimeout(function () {
              setRevisionsOpen((open) => false);
            }, 0);
          }}
          className="button button--secondary no-thanks"
        >
          No Thanks
        </button>
        <button
          onClick={() => {
            const revision = createRevision(project, text);

            dispatch({ type: "revisions/add", revision });

            setTimeout(function () {
              setRevisionsOpen((open) => false);
            }, 0);
          }}
          className="button button--primary"
        >
          Add Description
        </button>
      </div>
    </div>
  );
}

function SidebarTooltip({ children }) {
  return <div className="sidebar__tooltip">{children}</div>;
}

function Sidebar({
  tool,
  setTool,
  setDismiss,
  shape,
  setShape,
  stairsTool,
  setStairsTool,
}) {
  const dispatch = useDispatch();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [revisionsOpen, setRevisionsOpen] = useState(false);
  const [revisionsMenuOpen, setRevisionsMenuOpen] = useState(false);

  return (
    <aside className="app__sidebar">
      {optionsOpen && (
        <Options
          dispatch={dispatch}
          setOptionsOpen={setOptionsOpen}
          setRevisionsOpen={setRevisionsMenuOpen}
        />
      )}
      {revisionsMenuOpen && (
        <RevisionsMenu setRevisionsMenuOpen={setRevisionsMenuOpen} />
      )}
      {revisionsOpen && (
        <RevisionsDescription
          dispatch={dispatch}
          setRevisionsOpen={setRevisionsOpen}
        />
      )}
      <div className="app__sidebar-tools">
        {tool === "shapes" && (
          <div className="shapes__selection">
            <IconButton
              icon="rectangle"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setShape(() => "rectangle");
              }}
              active={shape === "rectangle"}
            />
            <IconButton
              icon="circle"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setShape(() => "circle");
              }}
              active={shape === "circle"}
            />
            <IconButton
              icon="triangle"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setShape(() => "triangle");
              }}
              active={shape === "triangle"}
            />
            <IconButton
              icon="arrow-down"
              className="sidebar__icon-button sidebar-icon-button--line"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setShape(() => "line");
              }}
              active={shape === "line"}
            />
          </div>
        )}
        {tool === "stairs" && (
          <div className="stairs__selection">
            <IconButton
              icon="stairs"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setStairsTool(() => "stairs");
              }}
              active={stairsTool === "stairs"}
            >
              <SidebarTooltip>Insert Stairs</SidebarTooltip>
            </IconButton>
            <IconButton
              icon="rectangle"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setStairsTool(() => "landing");
              }}
              active={stairsTool === "landing"}
            >
              <SidebarTooltip>Insert Landing</SidebarTooltip>
            </IconButton>
            <IconButton
              icon="ramp"
              className="sidebar__icon-button"
              onClick={() => {
                defaultSidebarClick(dispatch)();
                setStairsTool(() => "ramp");
              }}
              active={stairsTool === "ramp"}
            >
               <SidebarTooltip>Insert Ramp</SidebarTooltip>
            </IconButton>
          </div>
        )}
        <header className="app__sidebar-header">
          <IconButton
            icon="menu"
            className="sidebar__icon-button"
            onClick={() => {
              setOptionsOpen((prevState) => !prevState);
            }}
          />
        </header>
        <div className="app__sidebar-section">
          <IconButton
            icon="run"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("run", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "run"}
          >
            <SidebarTooltip>Insert Run</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="post"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("post", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "post"}
          >
            <SidebarTooltip>Insert Post</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="gate"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("gate", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "gate"}
          >
            <SidebarTooltip>Insert Gate</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="handrail"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("handrail", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "handrail"}
          >
            <SidebarTooltip>Insert Handrail</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="stairs"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("stairs", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "stairs"}
          >
            <SidebarTooltip>Insert Stairs</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="image"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("image", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "image"}
          >
            <SidebarTooltip>Edit Image</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="shapes"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("shapes", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "shapes"}
          >
            <SidebarTooltip>Insert Shapes</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="note"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("note", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "note"}
          >
            <SidebarTooltip>Insert Notes</SidebarTooltip>
          </IconButton>
        </div>
        <div className="app__sidebar-section">
          <IconButton
            icon="pan"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("pan", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "pan"}
          >
            <SidebarTooltip>Pan</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="move"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) => updateTool("move", currentTool));
              setDismiss(() => false);
            }}
            active={tool === "move"}
          >
            <SidebarTooltip>Move</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="edit-selection"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) =>
                updateTool("edit-selection", currentTool)
              );
              setDismiss(() => false);
            }}
            active={tool === "edit-selection"}
          >
            <SidebarTooltip>Edit Selection</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="delete-selection"
            className="sidebar__icon-button"
            onClick={() => {
              defaultSidebarClick(dispatch)();
              setTool((currentTool) =>
                updateTool("delete-selection", currentTool)
              );
              setDismiss(() => false);
            }}
            active={tool === "delete-selection"}
          >
            <SidebarTooltip>Delete Selection</SidebarTooltip>
          </IconButton>
        </div>
        <div className="app__sidebar-section">
          <IconButton
            icon="undo"
            className="sidebar__icon-button"
            onClick={() => {
              dispatch(ActionCreators.undo());
            }}
          >
            <SidebarTooltip>Undo</SidebarTooltip>
          </IconButton>
          <IconButton
            icon="redo"
            className="sidebar__icon-button"
            onClick={() => {
              dispatch(ActionCreators.redo());
            }}
          >
            <SidebarTooltip>Redo</SidebarTooltip>
          </IconButton>
        </div>
      </div>
      <footer className="app__sidebar-footer">
        <IconButton
          icon="save"
          className="sidebar__icon-button icon-button--save"
          onClick={() => {
            setRevisionsOpen((state) => !state);
          }}
        >
          <SidebarTooltip>Save Revision</SidebarTooltip>
        </IconButton>
      </footer>
    </aside>
  );
}

export default Sidebar;
