import { Record, Map, List, OrderedMap } from "immutable";
import { CustomerRet } from "../data/parsers/customerParser";

const Overrides = Record({
  itemListChanges: List([]),
  propertyChanges: List([]),
});

export type Overrides = InstanceType<typeof Overrides>;

const Point = Record({
  x: 0,
  y: 0,
});

type Point = InstanceType<typeof Point>;

const Labels = Record({
  showLabels: true,
  runLabels: true,
  distanceLabel: true,
  stairsHorizontalLabel: true,
});

type Labels = InstanceType<typeof Labels>;

const Corner = Record({
  id: Map<string, string>(),
  identity: 0,
  point: { x: 0, y: 0 },
  type: "double",
  insideCorner: false,
  cableContinuesThrough: false,
});

type Corner = InstanceType<typeof Corner>;

const Run = Record({
  id: "",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  stairs: null,
  settings: Map(),
  // Use for phantom posts.
  endPosts: Map<string, Post>(),
  postTypes: Map(),
  gates: Map<string, Gate>(),
  type: "run",
  labels: Labels(),
  overrides: Overrides(),
});

type Run = InstanceType<typeof Run>;

const Post = Record({
  id: "",
  x: 0,
  y: 0,
  type: "post",
  phantom: false,
  overrides: Overrides(),
});

type Post = InstanceType<typeof Post>;

const Gate = Record({
  id: "",
  p1: {},
  p2: {},
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  type: "gate",
  opening: "normal",
  side: "normal",
  overrides: Overrides(),
});

type Gate = InstanceType<typeof Gate>;

const Handrail = Record({
  id: "",
  p1: {},
  p2: {},
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  type: "handrail",
  side: "default",
  // Whether to flip sides depending on how chain of handrails forms.
  inOut: {
    start: "default",
    end: "default",
  },
  parts: {
    type: "default",
    linkColor: "default",
    start: {
      endCap: "default",
      loop: "default",
      wallReturn: "default",
    },
    end: {
      endCap: "default",
      loop: "default",
      wallReturn: "default",
    },
  },
  run: null,
  stairs: null,
  labels: Labels({ distanceLabel: false }),
  overrides: Overrides(),
});

type Handrail = InstanceType<typeof Handrail>;

const Image = Record({
  id: "",
  url: "",
  point: Point(),
  scale: 1,
  rotate: 0,
  originalWidth: 0,
  originalHeight: 0,
  width: 0,
  height: 0,
  properties: {},
});

type Image = InstanceType<typeof Image>;

const Shape = Record({
  id: "",
  type: "square",
  color: "#00A3FF",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  labels: Labels(),
});

type Shape = InstanceType<typeof Shape>;

const Note = Record({
  id: "",
  x: 0,
  y: 0,
  text: "",
  fontSize: "16px",
  isEditing: true,
});

type Note = InstanceType<typeof Note>;

const Stairs = Record({
  id: "",
  type: "stairs",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  metaId: "",
  angle: 35,
  orientation: "vertical",
  rotate: Map({ axis: "top-left", angle: 0 }),
  stairsEdges: Map(),
  isRamp: false,
  labels: Labels(),
});

type Stairs = InstanceType<typeof Stairs>;

const Landing = Record({
  id: "",
  type: "landing",
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
  metaId: "",
  angle: 0,
  twister: false,
  stairsEdges: Map(),
  rotate: Map({ axis: "top-left", angle: 0 }),
  labels: Labels(),
});

type Landing = InstanceType<typeof Landing>;

const EditWindow = Record({
  type: "",
  object: {},
});

type EditWindow = InstanceType<typeof EditWindow>;

const Canvas = Record({
  id: "initial",
  name: "New Canvas",
  runs: Map<string, Run>(),
  pan: { x: 0, y: 0 },
  scale: 1,
  posts: Map<string, Post>(),
  shapes: Map<string, Shape>(),
  gates: Map<string, Gate>(),
  images: Map<string, Image>(),
  notes: Map<string, Note>(),
  stairs: Map<string, Stairs>(),
  handrails: Map<string, Handrail>(),
  corners: Map<string, Corner>(),
  labels: {
    runs: "show",
  },
  overrides: List<Overrides>(),
  edit: EditWindow(),
});

type Canvas = InstanceType<typeof Canvas>;

type CustomerType = {
  id: string;
  name: string | undefined;
  quickbooksId: string;
  data: any;
  exportedToQB: boolean;
  quickbooksData: CustomerRet;
  status: string;
  version: string;
};

const Customer = Record<CustomerType>({
  id: "",
  name: "",
  quickbooksId: "",
  data: {},
  exportedToQB: false,
  // @ts-ignore
  quickbooksData: {},
  status: "active",
  version: "1",
});

type Customer = InstanceType<typeof Customer>;

const Contact = Record({
  id: "",
  name: "",
  phone: "",
  email: "",
  companyName: "",
  billingAddress: {},
  shippingAddress: {},
  note: "",
});

type Contact = InstanceType<typeof Contact>;

const Estimator = Record({
  id: "",
  name: "",
  quickbooksId: "",
  quickbooksData: {},
  abbreviation: "",
  email: "",
  status: "active",
  version: "1",
});

type Estimator = InstanceType<typeof Estimator>;

export type ProjectSettingsType = {
  projectTitle: string;
  status: string;
  postSpacing: string;
  customPostSpacing: number;
  cableSize: string;
  cableType: string;
  blackOxideBundles: string;
  blackOxideType?: string;
  railHeight: "36" | "42" | "custom";
  customRailHeight: {
    feet: number;
    inches: number;
  };
  mountStyle: string;
  nearSaltwater: "yes" | "no" | "";
  nearPoolwater: "yes" | "no" | "";
  postMaterial: string;
  aluminumColor: string;
  stainlessPostShape: string;
  woodType: string;
  toprailMaterial: string;
  aluminumToprailType: string;
  stainlessSteelToprailType: string;
  stainlessPostCapType: string;
  stainlessWeldType: string;
  resellerPermit: boolean;
  cableFittingType: string;
  woodToprailType: string;
  overrages: boolean;
  woodToprailSetup: string;
  woodAlumP2PStairsSetup: string;
  woodToprailSize: string;
  thruPostType: string;
  factorySwaged: string;
  finelineSwaged: string;
  receiverStudSize: string;
  swagelessType: string;
  endFitting: string;
  hardwareType: string;
  hardwareSize: string;
  concreteHardwareType: string;
  concreteHardwareSize: string;
  includePassivation: string;
  includeElectroPolish: string;
  projectType: string;
  cableCutter: {
    include: boolean;
    size: string;
  };
  cableCrimper: {
    include: boolean;
    die: string;
  };
  shipToAddress: {
    validated: boolean;
    address: any;
    overrideQBAddress: boolean;
  };
  handrailParts: {
    type: string;
    linkColor: string;
    start: {
      endCap: string;
      loop: string;
      wallReturn: string;
    };
    end: {
      endCap: string;
      loop: string;
      wallReturn: string;
    };
  };
  fasciaBracketType: string;
  fasciaMountType: string;
  includeCoverPlate: string;
  ponyWallSize: string;
  trackingDetails: {
    utm: {
      source: {
        value: null;
        set: boolean;
      };
      medium: {
        value: null;
        set: boolean;
      };
      campaign: {
        value: null;
        set: boolean;
      };
    };
    callrail: { value: null; set: boolean };
  };
};

const ProjectSettings = Record<ProjectSettingsType>({
  projectTitle: "Untitled Project",
  status: "pending",
  postSpacing: "4",
  customPostSpacing: 4,
  cableSize: "1 x 19 - 1/8”",
  cableType: "default",
  blackOxideBundles: "use-bundles",
  railHeight: "36",
  customRailHeight: { feet: 3, inches: 0 },
  mountStyle: "deck",
  nearSaltwater: "",
  nearPoolwater: "",
  postMaterial: "aluminum",
  aluminumColor: "black",
  stainlessPostShape: "square",
  woodType: "4x4 Batu",
  toprailMaterial: "aluminum",
  aluminumToprailType: "rectangular",
  stainlessSteelToprailType: "round",
  stainlessPostCapType: "hi-profile",
  stainlessWeldType: "none",
  resellerPermit: false,
  cableFittingType: "thru-post",
  woodToprailType: "clear-cedar",
  overrages: true,
  // Can be wood, or wood-p2p.
  woodToprailSetup: "wood",
  // Can be in-between, or on-top-brackets.
  woodAlumP2PStairsSetup: "in-between",
  woodToprailSize: "5/4x4",
  thruPostType: "field-swaged",
  factorySwaged: "one-end-swaged",
  finelineSwaged: "field-swaged",
  receiverStudSize: "short",
  swagelessType: "type-1",
  endFitting: "acorn",
  hardwareType: "lags",
  hardwareSize: '3/8" x 4 1/2"',
  concreteHardwareType: "tapcon",
  concreteHardwareSize: '3/8" x 3"',
  includePassivation: "no",
  includeElectroPolish: "no",
  projectType: "none",
  cableCutter: { include: true, size: "auto" },
  cableCrimper: { include: true, die: "auto" },
  shipToAddress: { validated: false, address: {}, overrideQBAddress: false },
  handrailParts: {
    type: "link",
    linkColor: "black",
    start: {
      endCap: "include",
      loop: "exclude",
      wallReturn: "exclude",
    },
    end: {
      endCap: "include",
      loop: "exclude",
      wallReturn: "exclude",
    },
  },
  fasciaBracketType: "none",
  fasciaMountType: "bracket",
  includeCoverPlate: "yes",
  ponyWallSize: "2x4",
  trackingDetails: {
    utm: {
      source: { value: null, set: false },
      medium: { value: null, set: false },
      campaign: { value: null, set: false },
    },
    callrail: { value: null, set: false },
  },
});

export type ProjectSettings = InstanceType<typeof ProjectSettings>;

export type ProjectSettingsKeys = keyof ProjectSettingsType;

const ProjectRevisionState = Record({
  id: "",
  runs: Map<string, Run>(),
  posts: Map<string, Post>(),
  shapes: Map<string, Shape>(),
  gates: Map<string, Gate>(),
  corners: Map<string, Corner>(),
  images: Map<string, Image>(),
  notes: Map<string, Note>(),
  stairs: Map<string, Stairs>(),
  handrails: Map<string, Handrail>(),
  edit: EditWindow(),
  settings: ProjectSettings(),
  settingsOpen: false,
  overrides: List(),
  canvases: Map({ initial: Canvas() }),
  currentCanvas: "initial",
  tax: {
    set: false,
    value: 0,
  },
});

type ProjectRevisionState = InstanceType<typeof ProjectRevisionState>;

const ProjectRevision = Record({
  date: "",
  datetime: "",
  id: "initial",
  description: "",
  state: ProjectRevisionState(),
});

type ProjectRevision = InstanceType<typeof ProjectRevision>;

const Project = Record({
  runs: Map<string, Run>(),
  posts: Map<string, Post>(),
  shapes: Map<string, Shape>(),
  gates: Map<string, Gate>(),
  corners: Map<string, Corner>(),
  images: Map<string, Image>(),
  notes: Map<string, Note>(),
  stairs: Map<string, Stairs>(),
  handrails: Map<string, Handrail>(),
  edit: EditWindow(),
  settings: ProjectSettings(),
  settingsOpen: true,
  revisions: OrderedMap<string, ProjectRevision>(),
  currentRevision: "",
  canvases: Map<string, Canvas>(),
  canvasMenuOpen: Map(),
  currentCanvas: "",
  overrides: List(),
  // Is a map of the sales rep assigned to project.
  estimators: Map<string, any>(),
  // Is an id of the main estimator who runs the project.
  mainEstimators: Map<string, any>(),
  // Whether estimator is locked in.
  estimatorsVerified: false,
  createdOn: "",
  quickbooksId: "",
  // Auto increment field used as estimate number.
  projectEstimate: null,
  customer: "",
  version: "2",
  id: "",
  isSalesOrder: "",
  exportToQuickbooks: false,
  sequenceId: 1,
  tax: {
    set: false,
    value: 0,
  },
  frozenProject: {
    id: null,
  } as any,
  frozenEstimate: {
    id: null,
  } as any,
});

type Project = InstanceType<typeof Project>;

export {
  Project,
  ProjectRevision,
  ProjectRevisionState,
  EditWindow,
  ProjectSettings,
  Estimator,
  Contact,
  Customer,
  Canvas,
  Landing,
  Stairs,
  Note,
  Shape,
  Image,
  Handrail,
  Gate,
  Post,
  Run,
  Corner,
  Labels,
  Point,
  Overrides,
};
