// @ts-nocheck
import {
  addItem,
  addStainlessPostCapsForFasciaMount,
  getPostAttributeByMaterial,
  handleOverrides,
} from ".";
import {
  getStainlessPostCapUpcs,
  postUpcs,
  lagHardwareUpcs,
  undrilledPostUpcs,
  getAluminumMountingHardwareCoverUpcs,
  fasciaDirectMountSpacerUpc,
  fasciaDirectMountDrillingService,
  boltHardwareUpcs,
  listOf38HexBoltUpcs,
  concreteHardwareUpcs,
  fasciaBracketUpcs,
} from "./getParts";
import { isMarine } from "./utils/isMarine";

export function PostItemList(settings, post, inventory) {
  let itemList = {};

  if (post.phantom) {
    return {
      getItemList: function () {
        return itemList;
      },
    };
  }

  const postsUpcs = postUpcs(); // eslint-disable-line no-unused-vars
  const undrilledPosts = undrilledPostUpcs();

  const {
    postMaterial,
    mountStyle,
    railHeight,
    aluminumColor,
    stainlessPostShape,
    woodType,
  } = settings;

  const postAttribute = getPostAttributeByMaterial(postMaterial, {
    aluminumColor,
    stainlessPostShape,
    woodType,
  });

  if (postMaterial === "customer-provided") {
    return {
      getItemList: function () {
        return itemList;
      },
    };
  }

  // If switching to drilled posts use below, otherwise maintain undrilled posts.
  // const thePostUpcs = postsUpcs[postMaterial][postAttribute][mountStyle][railHeight];
  const theUndrilledPostUpcs =
    undrilledPosts[postMaterial][postAttribute][mountStyle][railHeight];

  if (postMaterial === "stainless-steel") {
    const postCapUpcs = getStainlessPostCapUpcs();

    itemList = addItem(
      itemList,
      inventory,
      postCapUpcs[stainlessPostShape].terminal,
      1
    );
  }

  itemList = addItem(itemList, inventory, theUndrilledPostUpcs.terminal, 1);

  // Add hardware.
  const postsForRun = { terminal: 1 };
  const fasciaUpcs = fasciaBracketUpcs();
  const fasciaType = settings.fasciaBracketType;
  const postType = settings.postMaterial;

  if (postType === "aluminum" || postType === "wood") {
    if (
      fasciaType === "none" ||
      fasciaType === "direct" ||
      fasciaType === "direct-spacer" ||
      fasciaType === "alum-4hole-bracket" ||
      fasciaType === "alum-4hole-bracket-shims"
    ) {
      const totalPostCount = 1;

      if (fasciaType === "direct-spacer") {
        itemList = addItem(
          itemList,
          inventory,
          fasciaDirectMountSpacerUpc(),
          // 2 spacers per post.
          totalPostCount * 2
        );
      }

      // Add 2 hardware units for  mounting fascia posts.
      let quantityPerHole = 2;

      if (
        fasciaType === "ss-square-4hole-rect" ||
        fasciaType === "ss-round-4hole-rect" ||
        fasciaType === "alum-4hole-bracket-shims" ||
        fasciaType === "alum-4hole-bracket"
      ) {
        quantityPerHole = 4;
      }

      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc = lagUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc,
          quantityPerHole * totalPostCount
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPostCount
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc = boltUpcs[marine][hardwareSize];

        // 3/8" Hex bolts.
        const hexBolts38 = listOf38HexBoltUpcs();

        if (hexBolts38.includes(boltUpc) && postType === "aluminum") {
          // Add 3/8" aluminum cover.
          let color = "black";

          if (postType === "aluminum") {
            color = settings.aluminumColor;
          }

          const coverUpcs = getAluminumMountingHardwareCoverUpcs();
          const coverUpc = coverUpcs[color];

          itemList = addItem(
            itemList,
            inventory,
            coverUpc,
            quantityPerHole * totalPostCount
          );
        }

        itemList = addItem(
          itemList,
          inventory,
          boltUpc,
          quantityPerHole * totalPostCount
        );
      }
    }
  }

  if (postType === "stainless-steel") {
    const stainlessSteelPostType = settings.stainlessPostShape;
    if (fasciaType === "direct" || fasciaType === "direct-spacer") {
      const totalPostCount = 1;

      // Add post caps to stainless systems that are direct fascia mounted.
      itemList = addStainlessPostCapsForFasciaMount(
        itemList,
        inventory,
        settings,
        postsForRun
      );

      itemList = addItem(
        itemList,
        inventory,
        fasciaDirectMountDrillingService(),
        totalPostCount
      );

      if (fasciaType === "direct-spacer") {
        itemList = addItem(
          itemList,
          inventory,
          fasciaDirectMountSpacerUpc(),
          // 2 spacers per post.
          totalPostCount * 2
        );
      }

      // Add 2 hardware units for  mounting fascia posts.
      const quantityPerHole = 2;
      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc = lagUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc,
          quantityPerHole * totalPostCount
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPostCount
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc = boltUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          boltUpc,
          quantityPerHole * totalPostCount
        );
      }
    }

    if (
      fasciaType === "ss-round-2hole-rect" ||
      fasciaType === "ss-round-2hole-round"
    ) {
      // Add post caps to stainless systems that the bracket does not have holder at bottom.
      itemList = addStainlessPostCapsForFasciaMount(
        itemList,
        inventory,
        settings,
        postsForRun
      );
    }

    if (
      fasciaType === "ss-square-4hole-rect" ||
      fasciaType === "ss-round-4hole-rect" ||
      fasciaType === "ss-round-2hole-rect" ||
      fasciaType === "ss-round-2hole-round"
    ) {
      if (postsForRun.terminal) {
        itemList = addItem(
          itemList,
          inventory,
          fasciaUpcs[postType][stainlessSteelPostType][fasciaType].terminal,
          postsForRun.terminal
        );
      }

      let quantityPerHole = 4;
      // Add hardware for mounting.
      if (
        fasciaType === "ss-square-4hole-rect" ||
        fasciaType === "ss-round-4hole-rect"
      ) {
        quantityPerHole = 4;
      }

      if (
        fasciaType === "ss-round-2hole-rect" ||
        fasciaType === "ss-round-2hole-round"
      ) {
        quantityPerHole = 2;
      }

      let totalPosts = 1;

      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc = lagUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc,
          quantityPerHole * totalPosts
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPosts
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc = boltUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          boltUpc,
          quantityPerHole * totalPosts
        );
      }
    }
  }

  itemList = handleOverrides(itemList, post);

  return {
    getItemList: function () {
      return itemList;
    },
  };
}
