// @ts-nocheck
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "./IconButton";
import Icon from "./Icon";
import PartsList from "./PartsList";
import { getTotal } from "../partsList";
import { serviceUrl } from "../environment";
import {
  projectEstimateNumber,
  getAddressForAvatax,
  roundToHundreth,
  findProductByFullName,
  getDollarAmount,
} from "../utils";
import { getItemLists, getMasterItemList } from "../utils/partsList";
import ExportSalesOrderButton from "./ExportSalesOrder/ExportSalesOrder";
import { getPercentDiscountUpcs } from "../utils/discount";
import { InventoryContext } from "../App";
import { isProjectFrozen } from "../utils/freezeProject";
import { CustomerContext } from "./SingleProjectView";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function getDate() {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return year + "-" + month + "-" + day;
}

function Total(props) {
  const { customer } = props;

  const taxState = useSelector((state) => state.state.present.tax);
  const project = useSelector((state) => state.state.present);
  const settings = project.settings;

  const [price, setPrice] = useState(props.total);
  const [taxCalculating, setTaxCalcuating] = useState(false);
  const [taxIsShown, setTaxIsShown] = useState(taxState.set);
  const dispatch = useDispatch();

  const tax = taxState.value;

  useEffect(() => {
    if (props.total !== price) {
      setPrice(props.total);
      dispatch({ type: "tax/reset" });
      setTaxIsShown(false);
      setTaxCalcuating(false);
    }
  }, [props.total, dispatch, price]);

  const request = {
    companyCode: "Stainless Cable & Railing",
    code: getRandomInt(10000).toString(),
    type: "SalesOrder",
    date: getDate(),
    customerCode: "DEFAULT",
    addresses: {
      singleLocation: {},
    },
    lines: props.taxItemList,
  };

  if (customer) {
    request.addresses.singleLocation = getAddressForAvatax(customer, settings);
  }

  if (!taxIsShown || props.resellerPermit || props.total < 0) {
    return (
      <div className="parts-list__total-area">
        <div className="parts-list__total">
          <span className="parts-list__total-label">Total: $</span>
          <span className="parts-list__total-value">
            {getDollarAmount(props.total)}
          </span>
        </div>
        {!props.resellerPermit &&
          customer &&
          request.addresses.singleLocation !== false && (
            <div className="parts-list__tax">
              <button
                className="tax__button"
                onClick={() => {
                  if (isProjectFrozen(project)) {
                    dispatch({
                      type: "window/open-project-frozen-dialog",
                    });

                    // Exit early if frozen.
                    return;
                  }

                  setTaxIsShown(true);
                  setTaxCalcuating(true);
                  const url = serviceUrl("avatax");

                  fetch(url, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(request), // body data type must match "Content-Type" header
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => {
                      const totalTax = data.totalTax || 0;

                      dispatch({ type: "tax/set", tax: totalTax });
                      setTaxCalcuating(false);
                    })
                    .catch((error) => {
                      dispatch({ type: "tax/set", tax: 0 });
                      setTaxCalcuating(false);
                      console.error("tax", error);
                    });
                }}
              >
                <Icon icon="percent" className="tax__button-icon" />
              </button>
            </div>
          )}
      </div>
    );
  } else {
    return (
      <div className="parts-list__tax-showing">
        <div className="parts-list__tax-area">
          <div className="parts-list__total parts-list__subtotal">
            <span className="parts-list__total-label">Subtotal:</span>
            <span className="parts-list__total-value">${props.total}</span>
          </div>
        </div>
        <div className="parts-list__tax-area">
          <div className="parts-list__total">
            <span className="parts-list__total-label">Sales Tax:</span>
            <span className="parts-list__total-value">
              {taxCalculating ? "..." : "$" + tax}
            </span>
          </div>
          <div className="parts-list__tax">
            <button
              className="tax__button tax__button--delete"
              onClick={() => {
                if (isProjectFrozen(project)) {
                  dispatch({
                    type: "window/open-project-frozen-dialog",
                  });

                  // Exit early if frozen.
                  return;
                }

                dispatch({ type: "tax/reset" });
                setTaxIsShown(false);
                setTaxCalcuating(false);
              }}
            >
              <Icon
                icon="delete"
                className="tax__button-icon tax__button-icon-delete"
              />
            </button>
          </div>
        </div>
        <div className="parts-list__tax-area">
          <div className="parts-list__total parts-list__grandtotal">
            <span className="parts-list__total-label">Total:</span>
            <span className="parts-list__total-value">
              ${roundToHundreth(props.total + tax)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function ProjectSummary({ settings }) {
  const postMaterials = {
    aluminum: "Aluminum",
    "stainless-steel": "Stainless Steel",
    wood: "Wood",
    "customer-provided": "Customer Provided",
  };

  const ssToprailType = {
    round: '2" Round',
    flat: 'Flat 0.5" x 2"',
    "2507": '2507 Flat 1" x 2"',
  };

  const aluminumToprailTypes = {
    rectangular: "Rectangular",
    shaped: "Shaped",
    "alum-p2p": "Post to Post",
    "wood-p2p": "Wood Post to Post",
  };

  const ssPostTypes = {
    square: "Square",
    round: "Round",
    "2507-square": "2507 Square",
  };

  const aluminumColors = {
    black: "Black",
    bronze: "Bronze",
    clay: "Clay",
    Natural: "Natural",
    white: "White",
    "anodized-black": "Anodized Black",
    "anodized-bronze": "Anodized Bronze",
    "anodized-clear": "Anodized Clear",
  };

  const mountStyles = {
    core: "Core / Ground",
    deck: "Deck",
    fascia: "Fascia / Side",
    "pony-wall": "Pony Wall",
  };

  return (
    <div className="project-summary">
      <div className="project-summary__item">
        <span className="project-summary__item-label">Cable Size</span>
        <span className="project-summary__item-value">
          {settings.cableSize}
        </span>
      </div>
      <div className="project-summary__item">
        <span className="project-summary__item-label">Rail Height</span>
        <span className="project-summary__item-value">
          {settings.railHeight !== "custom"
            ? settings.railHeight + '"'
            : "Custom"}
        </span>
      </div>
      <div className="project-summary__item">
        <span className="project-summary__item-label">Mount Style</span>
        <span className="project-summary__item-value">
          {mountStyles[settings.mountStyle]}
        </span>
      </div>
      <div className="project-summary__item">
        <span className="project-summary__item-label">Post Material</span>
        <span className="project-summary__item-value">
          {postMaterials[settings.postMaterial]}
        </span>
      </div>
      {settings.postMaterial === "aluminum" && (
        <div className="project-summary__item">
          <span className="project-summary__item-label">Color</span>
          <span className="project-summary__item-value">
            {aluminumColors[settings.aluminumColor]}
          </span>
        </div>
      )}
      {settings.postMaterial === "stainless-steel" && (
        <div className="project-summary__item">
          <span className="project-summary__item-label">Post Type</span>
          <span className="project-summary__item-value">
            {ssPostTypes[settings.stainlessPostShape]}
          </span>
        </div>
      )}
      <div className="project-summary__item">
        <span className="project-summary__item-label">Toprail</span>
        <span className="project-summary__item-value">
          {postMaterials[settings.toprailMaterial]}
        </span>
      </div>
      {settings.toprailMaterial === "aluminum" && (
        <div className="project-summary__item">
          <span className="project-summary__item-label">Toprail Type</span>
          <span className="project-summary__item-value">
            {aluminumToprailTypes[settings.aluminumToprailType]}
          </span>
        </div>
      )}
      {settings.toprailMaterial === "stainless-steel" && (
        <div className="project-summary__item">
          <span className="project-summary__item-label">Toprail Type</span>
          <span className="project-summary__item-value">
            {ssToprailType[settings.stainlessSteelToprailType]}
          </span>
        </div>
      )}
    </div>
  );
}

function Details() {
  const [expanded, setExpanded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const state = useSelector((state) => state.state);
  const project = state.present;
  const dispatch = useDispatch();

  const [summaryOpen, setSummaryOpen] = useState(true);

  const { customer } = useContext(CustomerContext);

  const inventoryList = useContext(InventoryContext);

  const customerName = customer?.name || "Customer not assigned";

  const canvases = state.present.canvases;

  const itemLists = getItemLists(canvases, inventoryList, state.present);
  const getTotals = itemLists.map((itemList) => getTotal(itemList));
  const total = getTotals.reduce(
    (acc, total) => roundToHundreth(acc + total),
    0
  );

  const masterItemList = getMasterItemList(itemLists);

  const taxItemList = Object.values(masterItemList).reduce((theList, item) => {
    if (item.type !== "subtotal") {
      theList.push({ amount: item.total });
    }

    return theList;
  }, []);

  // Handle whether items are selected in list.
  const [selected, setSelected] = useState({});

  let isSelected = false;

  const selectedValues = Object.values(selected);

  for (let i = 0, len = selectedValues.length; i < len; i++) {
    if (selectedValues[i] === true) {
      isSelected = true;
      break;
    }
  }

  const partsListModifiersClassName = expanded
    ? "parts-list__footer-item-list-modifiers parts-list__footer-item-list-modifiers--expanded"
    : "parts-list__footer-item-list-modifiers";

  const partsListClassName = expanded
    ? "card parts-list parts-list--expanded"
    : "card parts-list";

  const partsListExpandText = expanded
    ? "Collapse Parts List"
    : "Expand Parts List";

  const projectSummaryIconClass = summaryOpen
    ? "parts-list__heading-icon project-summary--open"
    : "parts-list__heading-icon";
  return (
    <div className="app__details-container">
      <aside className="app__details">
        {processing && (
          <div className="app__processing">
            Exporting to Quickbooks. DO NOT CLOSE TAB UNTIL COMPLETE.
          </div>
        )}
        <header className="card details__header">
          <img
            alt="Stainless Cable & Railing"
            src={process.env.PUBLIC_URL + "/logo.png"}
            className="details__header-image"
          />
          <div className="details__header-details">
            <div className="details__header-content">
              <p className="details__client-name">{customerName}</p>
              <p className="details__project-id">
                {projectEstimateNumber(project)}
                {project.isSalesOrder && <span> - Sales Order</span>}
                {project.frozenProject.id && <span> (Frozen)</span>}
                {project.frozenEstimate.id && <span> (Frozen)</span>}
              </p>
            </div>
            <IconButton
              onClick={() => {
                dispatch({ type: "settings/toggle" });
              }}
              icon="settings"
              className="header__icon-button"
            />
          </div>
        </header>
        <div className={partsListClassName}>
          <header className="parts-list__header">
            <button
              onClick={() => {
                setSummaryOpen((open) => !open);
              }}
              className="parts-list__header-button"
            >
              <p className="parts-list__heading">Project Summary</p>
              <Icon icon="chev-down" className={projectSummaryIconClass} />
            </button>
            {summaryOpen && <ProjectSummary settings={project.settings} />}
            <button
              onClick={() =>
                setTimeout(() => {
                  setExpanded((toggle) => !toggle);
                }, 0)
              }
              className="parts-list__expand-list"
            >
              <Icon icon="chev-left" className="parts-list__expand-icon" />
              <div className="expand__tooltip">{partsListExpandText}</div>
            </button>
          </header>
          <PartsList
            expanded={expanded}
            setSelected={setSelected}
            selected={selected}
            getTotals={getTotals}
            inventory={inventoryList}
          />
          <footer className="parts-list__footer">
            <div className="parts-list__footer-totals">
              <div className={partsListModifiersClassName}>
                {isSelected && expanded && (
                  <button
                    onClick={() => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      if (
                        state.present.canvases &&
                        state.present.currentCanvas
                      ) {
                        let overrides = state.present.canvases.get(
                          state.present.currentCanvas
                        ).overrides;

                        const discountUpcs = getPercentDiscountUpcs();
                        Object.entries(selected).forEach(
                          ([upc, readyToDelete]) => {
                            if (discountUpcs[upc]) {
                              const override = {
                                type: "removePercentageDiscount",
                                upc: upc,
                              };

                              dispatch({
                                type: "project/add-override",
                                override: override,
                              });
                            } else {
                              overrides = overrides.push({
                                id: upc,
                                type: "removeItemFromCanvas",
                              });
                            }
                          }
                        );

                        dispatch({
                          type: "canvas/overrides",
                          overrides: overrides,
                        });
                        setSelected({});
                      }
                    }}
                    className="hardware__delete-item"
                  >
                    <Icon
                      icon="delete"
                      className="hardware__delete-item-icon"
                    />
                    <span className="hardware__delete-item-text">Delete</span>
                  </button>
                )}
                {isSelected && expanded && (
                  <button
                    onClick={() => {
                      if (isProjectFrozen(project)) {
                        dispatch({
                          type: "window/open-project-frozen-dialog",
                        });

                        // Exit early if frozen.
                        return;
                      }

                      if (
                        state.present.canvases &&
                        state.present.currentCanvas
                      ) {
                        const project = state.present;

                        let projectOverrides = project.overrides;

                        projectOverrides = projectOverrides.filter(
                          (override) => {
                            if (override.type === "removeGroupItem") {
                              return true;
                            }

                            if (override.item) {
                              if (override.item.upc) {
                                return !selected[override.item.upc];
                              }

                              if (override.item.BarCodeValue) {
                                return !selected[override.item.BarCodeValue];
                              }

                              if (
                                override.item.FullName &&
                                !override.item.upc &&
                                !override.item.BarCodeValue
                              ) {
                                return !selected[override.item.FullName];
                              }
                            }

                            return true;
                          }
                        );

                        let overrides = state.present.canvases.get(
                          state.present.currentCanvas
                        ).overrides;

                        overrides = overrides.filter((override) => {
                          // Always preserve removed group items.
                          if (override.type === "removeGroupItem") {
                            return true;
                          }

                          if (override.product) {
                            if (override.product.upc) {
                              return !selected[override.product.upc];
                            }

                            if (override.product.BarCodeValue) {
                              return !selected[override.product.BarCodeValue];
                            }

                            if (
                              override.product.FullName &&
                              !override.product.BarCodeValue &&
                              !override.product.upc
                            ) {
                              return !selected[override.product.FullName];
                            }
                          }

                          return true;
                        });

                        let overridesToAdd = [];

                        overrides.forEach((override) => {
                          if (override.product) {
                            if (
                              override.product.type &&
                              override.product.type === "group"
                            ) {
                              if (override.product.ItemGroupLine) {
                                override.product.ItemGroupLine.forEach(
                                  (item) => {
                                    const product = findProductByFullName(
                                      item.ItemRef.FullName,
                                      inventoryList
                                    );

                                    const upc =
                                      product.BarCodeValue ||
                                      product.upc ||
                                      product.FullName;

                                    if (selected[upc]) {
                                      overridesToAdd.push({
                                        id: upc,
                                        group: override,
                                        type: "removeGroupItem",
                                      });
                                    }
                                  }
                                );
                              }
                            }
                          }
                        });

                        overrides = overrides.concat(overridesToAdd);

                        const currentCanvas = state.present.canvases.get(
                          state.present.currentCanvas
                        );

                        let runs = currentCanvas.runs;

                        if (currentCanvas.runs.size) {
                          runs = currentCanvas.runs.map((run) => {
                            if (run.overrides) {
                              let runOverridesToAdd = [];
                              return run.setIn(
                                ["overrides", "itemListChanges"],
                                run.overrides.itemListChanges
                                  .filter((override) => {
                                    // Always preserve items removed from group.
                                    if (override.type === "removeGroupItem") {
                                      return true;
                                    }

                                    if (override.product) {
                                      if (
                                        override.product.type &&
                                        override.product.type === "group"
                                      ) {
                                        if (override.product.ItemGroupLine) {
                                          override.product.ItemGroupLine.forEach(
                                            (item) => {
                                              const product =
                                                findProductByFullName(
                                                  item.ItemRef.FullName,
                                                  inventoryList
                                                );

                                              if (product) {
                                                const upc =
                                                  product.BarCodeValue ||
                                                  product.upc ||
                                                  product.FullName;

                                                if (selected[upc]) {
                                                  runOverridesToAdd.push({
                                                    id: upc,
                                                    group: override,
                                                    type: "removeGroupItem",
                                                  });
                                                }
                                              }
                                            }
                                          );
                                        }
                                      }

                                      if (override.product.upc) {
                                        return !selected[override.product.upc];
                                      }

                                      if (override.product.BarCodeValue) {
                                        return !selected[
                                          override.product.BarCodeValue
                                        ];
                                      }

                                      if (
                                        override.product.FullName &&
                                        !override.product.BarCodeValue &&
                                        !override.product.upc
                                      ) {
                                        return !selected[
                                          override.product.FullName
                                        ];
                                      }
                                    }

                                    return true;
                                  })
                                  .concat(runOverridesToAdd)
                              );
                            }

                            return run;
                          });
                        }

                        dispatch({
                          type: "canvas/overrides",
                          overrides: overrides,
                          runs: runs,
                          project: projectOverrides,
                        });
                        setSelected({});
                      }
                    }}
                    className="hardware__delete-item hardware__delete-item--remove-overrides"
                  >
                    <Icon
                      icon="delete"
                      className="hardware__delete-item-icon"
                    />
                    <span className="hardware__delete-item-text">
                      Remove Overrides
                    </span>
                  </button>
                )}

                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({ type: "window/open-add-hardware-canvas" });
                  }}
                  className="hardware__add-item"
                >
                  <Icon icon="plus" className="hardware__add-item-icon" />
                  <span className="hardware__add-item-text">
                    Add Hardware Item
                  </span>
                  <div className="expand__tooltip">Add Hardware Item</div>
                </button>
                <button
                  onClick={() => {
                    if (isProjectFrozen(project)) {
                      dispatch({
                        type: "window/open-project-frozen-dialog",
                      });

                      // Exit early if frozen.
                      return;
                    }

                    dispatch({
                      type: "settings/edit",
                      settings: state.present.settings.set(
                        "overrages",
                        !state.present.settings.overrages
                      ),
                    });
                  }}
                  className={
                    state.present.settings.overrages
                      ? "hardware__add-item hw--overrages hardware__add-item--add-overages"
                      : "hardware__add-item hw--overrages hardware__delete-item--remove-overrides"
                  }
                >
                  <Icon
                    icon={
                      state.present.settings.overrages ? "success" : "alert"
                    }
                    className="hardware__add-item-icon"
                  />
                  <span className="hardware__delete-item-text">
                    {state.present.settings.overrages
                      ? "Overrages On"
                      : "Overrages Off"}
                  </span>
                  <div className="expand__tooltip expand__tooltip_custom">Toggle Overrages</div>
                </button>
              </div>
              {inventoryList.length && (
                <Total
                  total={total}
                  masterItemList={masterItemList}
                  customer={customer}
                  taxItemList={taxItemList}
                  resellerPermit={state.present.settings.resellerPermit}
                />
              )}
            </div>
            <div className="parts-list__footer-buttons">
              <ExportSalesOrderButton
                masterItemList={masterItemList}
                inventoryList={inventoryList}
                project={project}
                setProcessing={setProcessing}
                processing={processing}
              />
              <button
                onClick={() => {
                  dispatch({ type: "window/open-shipping-estimate" });
                }}
                className="parts-list__button button--secondary"
              >
                Create Shipping Estimate
              </button>
            </div>
          </footer>
        </div>
      </aside>
    </div>
  );
}

export default Details;
