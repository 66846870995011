// @ts-nocheck
import { Map } from "immutable";
import {
  getDistance,
  findProductByFullName,
  getUnitOfMeasure,
  getRunSettings,
  mergeSettings,
  getHypotenuseInFeetRaw,
  isPointBetweenPoints,
  pixelsPerFoot,
  calculateNumberOfSegmentsForCustomRailHeight,
  getNumberOfCableRuns,
} from "../../utils";
import { calculateCorners, runHasCorners } from "../../utils/corners";
import { DataType } from "../../data";
import sortItemList, { traverseSchema } from "../sortItemList";
import { calculateTouchupPaint, getTotalPosts } from "../../utils/partsList";
import { getRunIndex } from "../../utils/canvas";
import { addHandrails } from "../handrailParts";
import {
  getDistancesFromPostGroupsForRun,
  getGroupedPostsForRun,
} from "../../draw";
import { PostItemList } from "./PostItemList";
import { getPosts } from "../../utils/getPosts";
import {
  calculateRailingAmount,
  changeUnitOfMeasure,
  distanceToFeet,
  roundToHundreth,
  roundToNearestInch,
} from "..";
import { Corner, Project, ProjectSettings, Run, Stairs } from "../../entities";
import { AllInventoryTypes } from "../../data/fetch-inventory";
import { Item, ItemListType, MappedQBObject } from "./itemListTypes";
import { aluminumCustomPostDescriptions } from "./utils/aluminumCustomPostDescriptions";
import {
  boltHardwareUpcs,
  cableUpcs,
  cableUpcs2507,
  concreteHardwareHexboltUpcs,
  concreteHardwareToolUpcs,
  concreteHardwareUpcs,
  fasciaBracketUpcs,
  fasciaDirectMountDrillingService,
  fasciaDirectMountSpacerUpc,
  get2507FittingsUpcs,
  get2507InstallKitUpc,
  get2507StairWashers,
  getAluminumBasePlateForWoodToprailUpcs,
  getAluminumEpoxyKitUpcs,
  getAluminumEpoxyUpcs,
  getAluminumFasciaBracketParts,
  getAluminumMountingHardwareCoverUpcs,
  getAluminumP2PInlineUpcs,
  getAluminumP2PStairsUpcs,
  getAluminumSnapCoverUpcs,
  getAluminumToprailUpcs,
  getBasePadUpcs,
  getBasePlateBitForScrewsUpc,
  getBlackOxideCableBundleUpcs,
  getBlackOxideCableKitUpcs,
  getBlackOxideFittingsItems,
  getCableCrimperHolderUpc,
  getCableCutterUpcs,
  getCornerUpcs,
  getCoverPlateUpcs,
  getCrimperUpc,
  getCustomAluminumStockPostUpcs,
  getCustomStainlessStockPostUpcs,
  getDieCrimperUpcs,
  getElectroPolishUpc,
  getEndCapUpcs,
  getEpoxyKitUpcs,
  getEpoxyUpcs,
  getFasciaBracketInstallKitUpc,
  getFittingsItems,
  getGalvanicScrewProtectionUpc,
  getGateUpcs,
  getGenericHoleDrillingService,
  getGrommetUpcs,
  getNylonStairWasherUpcs,
  getNylonWasherUpcs,
  getOringUpcs,
  getP2PInstallKitUpc,
  getPassivationServiceName,
  getPassivationSprayUpc,
  getRustRescueUpc,
  getShrinkTubeUpcs,
  getStainless2507LowProfileStairUpcs,
  getStainless2507LowProfileUpcs,
  getStainlessFlangeUpcs,
  getStainlessLowProfileStairUpcs,
  getStainlessLowProfileUpcs,
  getStainlessPostCapUpcs,
  getStainlessSteelTopRailBracketUpcs,
  getStainlessSteelTopRailSpliceUpcs,
  getStainlessSteelToprailUpcs,
  getStainlessToprailInstallKitDrillBitUpcs,
  getStainlessWeldService,
  getStairWashers,
  getSurfaceMountInstallKitUpcs,
  getToprailCornerInstallKitUpcs,
  getToprailInstallKitUpcs,
  getWeldedBasePlateUpcs,
  getWoodP2PWasherUpc,
  getWoodSealerUpc,
  getWoodStockPostUpcs,
  getWoodToprailUpcs,
  lagHardwareUpcs,
  listOf38HexBoltUpcs,
  miterCuttingServices,
  ponyWallMitreCutServices,
  ponyWallPowderCoatingServices,
} from "./getParts";
import { postsForTheRun } from "./utils/postsForTheRun";
import { RunItemList } from "./RunItemList";
import { addWoodStain } from "./utils/addWoodStain";
import { TopRailLengths, calculateTopRailLengths } from "./utils/calculateTopRailLengths";
import { isMarine } from "./utils/isMarine";
import { arrayEquals } from "../../utils/arrayEquals";
import { Inventory } from "../../data/parsers/inventoryParser";

export function addStainlessPostCapsForFasciaMount(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  const postCapUpcs = getStainlessPostCapUpcs();

  const { stainlessPostShape } = settings;

  const totalTerminal =
    (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

  const totalIntermediate =
    (postsForRun.intermediate || 0) +
    (postsForRun.stairPostIntermediate || 0) +
    (postsForRun.stairPostTransition || 0);

  if (totalTerminal) {
    itemList = addItem(
      itemList,
      inventory,
      postCapUpcs[
        stainlessPostShape as keyof typeof postCapUpcs
      ].terminal.toString(),
      totalTerminal
    );
  }

  if (totalIntermediate) {
    itemList = addItem(
      itemList,
      inventory,
      postCapUpcs[
        stainlessPostShape as keyof typeof postCapUpcs
      ].intermediate.toString(),
      totalIntermediate
    );
  }
  return itemList;
}

export function addMiterCuttingServices(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  const miterServices = miterCuttingServices();

  if (
    settings.postMaterial === "stainless-steel" &&
    settings.stainlessPostShape === "2507-square" &&
    settings.mountStyle !== 'pony-wall'
  ) {
    const numPosts =
      (postsForRun?.stairPostIntermediate || 0) +
      (postsForRun?.stairPostTerminal || 0) +
      (postsForRun?.stairPostTransition || 0);

    itemList = addItem(
      itemList,
      inventory,
      miterServices["stainless-steel"]["2507"],
      numPosts
    );
  }

  return itemList;
}

export function addWeldedFlanges(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  // Cannot be used on Pony Wall mount.
  if (
    (settings.stainlessWeldType === "both" ||
      settings.stainlessWeldType === "bottom") &&
    // Ponywall already adds flanges elsewhere.
    settings.mountStyle !== "pony-wall" &&
    // 2507 posts already have flanges as part of assembly.
    settings.stainlessPostShape !== "2507-square"
  ) {
    const weldedBasePlateUpcs = getWeldedBasePlateUpcs();
    const weldedBasePlateUpc =
      weldedBasePlateUpcs[
        settings.stainlessPostShape as keyof typeof weldedBasePlateUpcs
      ];

    if (postsForRun.terminal) {
      itemList = addItem(
        itemList,
        inventory,
        weldedBasePlateUpc.terminal.toString(),
        postsForRun.terminal
      );
    }

    if (postsForRun.intermediate) {
      itemList = addItem(
        itemList,
        inventory,
        weldedBasePlateUpc.intermediate.toString(),
        postsForRun.intermediate
      );
    }

    if (postsForRun.stairPostIntermediate) {
      itemList = addItem(
        itemList,
        inventory,
        weldedBasePlateUpc.intermediate.toString(),
        postsForRun.stairPostIntermediate
      );
    }

    if (postsForRun.stairPostTerminal) {
      itemList = addItem(
        itemList,
        inventory,
        weldedBasePlateUpc.terminal.toString(),
        postsForRun.stairPostTerminal
      );
    }

    if (postsForRun.stairPostTransition) {
      itemList = addItem(
        itemList,
        inventory,
        weldedBasePlateUpc.intermediate.toString(),
        postsForRun.stairPostTransition
      );
    }
  }

  return itemList;
}

export function addWeldingServices(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  const totalPostCount =
    (postsForRun.terminal || 0) +
    (postsForRun.intermediate || 0) +
    (postsForRun.stairPostIntermediate || 0) +
    (postsForRun.stairPostTerminal || 0) +
    (postsForRun.stairPostTransition || 0);

  const stainlessWeldServices = getStainlessWeldService();

  // Welding already included in 2507 posts of standard heights.
  if (settings.stainlessPostShape === "2507-square" && settings.railHeight !== 'custom') {
    return itemList;
  }

  if (settings.stainlessWeldType === "top") {
    itemList = addItem(
      itemList,
      inventory,
      stainlessWeldServices.caps,
      totalPostCount
    );
  } else if (
    settings.stainlessWeldType === "bottom" &&
    settings.mountStyle !== "fascia" &&
    settings.mountStyle !== "core"
  ) {
    itemList = addItem(
      itemList,
      inventory,
      stainlessWeldServices.flange,
      totalPostCount
    );
  } else if (settings.stainlessWeldType === "both") {
    let postCapWeldingCount = totalPostCount;

    if (settings.stainlessPostShape === "2507-square" && settings.railHeight === 'custom' && settings.mountStyle === 'fascia') {
      postCapWeldingCount = postCapWeldingCount * 2;
    }

    itemList = addItem(
      itemList,
      inventory,
      stainlessWeldServices.caps,
      postCapWeldingCount
    );

    if (settings.mountStyle !== "fascia" && settings.mountStyle !== "core") {
      itemList = addItem(
        itemList,
        inventory,
        stainlessWeldServices.flange,
        totalPostCount
      );
    }
  }

  return itemList;
}

export function addStainlessLoProfileBrackets(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  let lowProfileStairsPostCapUpcs = getStainlessLowProfileStairUpcs();
  let lowProfilePostCapUpcs = getStainlessLowProfileUpcs();

  if (settings.stainlessSteelToprailType === "2507") {
    lowProfilePostCapUpcs = getStainless2507LowProfileUpcs();
    lowProfileStairsPostCapUpcs = getStainless2507LowProfileStairUpcs();
  }

  if (postsForRun.terminal) {
    itemList = addItem(
      itemList,
      inventory,
      lowProfilePostCapUpcs.normal.terminal,
      postsForRun.terminal
    );
  }

  if (postsForRun.intermediate) {
    itemList = addItem(
      itemList,
      inventory,
      lowProfilePostCapUpcs.normal.intermediate,
      postsForRun.intermediate
    );
  }

  if (postsForRun.stairPostIntermediate) {
    itemList = addItem(
      itemList,
      inventory,
      lowProfileStairsPostCapUpcs.intermediate,
      postsForRun.stairPostIntermediate
    );
  }

  if (postsForRun.stairPostTerminal) {
    itemList = addItem(
      itemList,
      inventory,
      lowProfileStairsPostCapUpcs.terminal,
      postsForRun.stairPostTerminal
    );
  }

  if (postsForRun.stairPostTransition) {
    itemList = addItem(
      itemList,
      inventory,
      lowProfileStairsPostCapUpcs.intermediate,
      postsForRun.stairPostTransition
    );
  }

  return itemList;
}

export function addStainlessHiProfileBrackets(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  const stainlessTopRailBrackets = getStainlessSteelTopRailBracketUpcs();

  const { postMaterial } = settings;

  const postType =
    postMaterial === "stainless-steel" ? settings.stainlessPostShape : "square";

  function addTerminalBrackets(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    postType: string
  ) {
    const stainlessTopRailBracket =
      stainlessTopRailBrackets[
        settings.stainlessSteelToprailType as keyof typeof stainlessTopRailBrackets
      ];
    const terminalBracketUpc =
      stainlessTopRailBracket[
        postType as keyof typeof stainlessTopRailBracket
      ].terminal.fixed.toString();

    itemList = addItem(
      itemList,
      inventory,
      terminalBracketUpc,
      postsForRun.terminal || 0
    );

    return itemList;
  }

  function addTerminalStairBrackets(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    postType: string
  ) {
    const terminalStairBracketUpc =
      stainlessTopRailBrackets[settings.stainlessSteelToprailType][postType][
        "terminal"
      ]["adjustable"];

    itemList = addItem(
      itemList,
      inventory,
      terminalStairBracketUpc,
      postsForRun.stairPostTerminal || 0
    );

    return itemList;
  }

  function addIntermediateStairBrackets(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    postType: string
  ) {
    const intermediateStairBracketUpc =
      stainlessTopRailBrackets[settings.stainlessSteelToprailType][postType][
        "intermediate"
      ]["adjustable"];

    itemList = addItem(
      itemList,
      inventory,
      intermediateStairBracketUpc,
      postsForRun.stairPostIntermediate || 0
    );

    return itemList;
  }

  function addTransitionStairBrackets(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    postType: string
  ) {
    const transitionStairBracketUpc =
      stainlessTopRailBrackets[settings.stainlessSteelToprailType][postType][
        "intermediate"
      ]["adjustable"];

    itemList = addItem(
      itemList,
      inventory,
      transitionStairBracketUpc,
      postsForRun.stairPostTransition || 0
    );

    return itemList;
  }

  function addIntermediateBrackets(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    postType: string
  ) {
    const intermediateBracketUpc =
      stainlessTopRailBrackets[settings.stainlessSteelToprailType][postType][
        "intermediate"
      ]["fixed"];

    itemList = addItem(
      itemList,
      inventory,
      intermediateBracketUpc,
      postsForRun.intermediate || 0
    );

    return itemList;
  }

  itemList = addTerminalBrackets(itemList, inventory, postType);
  itemList = addIntermediateBrackets(itemList, inventory, postType);
  itemList = addTerminalStairBrackets(itemList, inventory, postType);
  itemList = addIntermediateStairBrackets(itemList, inventory, postType);
  itemList = addTransitionStairBrackets(itemList, inventory, postType);
  return itemList;
}

export function addStainlessBaseFlanges(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  const flangeUpcs = getStainlessFlangeUpcs();

  // Do not add flanges for 2507 posts that are not custom height.
  if ( settings.postMaterial === "stainless-steel" && settings.stainlessPostShape === "2507-square" && settings.railHeight !== 'custom') {
    return itemList;
  }

  const totalTerminal =
    (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

  const totalIntermediate =
    (postsForRun.intermediate || 0) +
    (postsForRun.stairPostIntermediate || 0) +
    (postsForRun.stairPostTransition || 0);

  const flangeIntermediateUpc =
    flangeUpcs[
      settings.stainlessPostShape as keyof typeof flangeUpcs
    ].intermediate?.toString();

  const flangeTerminalUpc =
    flangeUpcs[
      settings.stainlessPostShape as keyof typeof flangeUpcs
    ].terminal?.toString();

  // If the mount style is core, we don't need to add any flanges.
  if (settings.mountStyle === "core") {
    return itemList;
  }

  if (flangeIntermediateUpc) {
    itemList = addItem(
      itemList,
      inventory,
      flangeIntermediateUpc,
      totalIntermediate
    );
  }

  if (flangeTerminalUpc) {
    itemList = addItem(itemList, inventory, flangeTerminalUpc, totalTerminal);
  }

  return itemList;
}

export function addCoverPlates(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  const coverPlateUpcs = getCoverPlateUpcs();

  // If the mount style is core, we don't need to add any cover plates.
  if (settings.mountStyle === "core") {
    return itemList;
  }

  if (settings.postMaterial === "aluminum") {
    const totalTerminal =
      (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

    const totalIntermediate =
      (postsForRun.intermediate || 0) +
      (postsForRun.stairPostIntermediate || 0) +
      (postsForRun.stairPostTransition || 0);

    const coverPlateUpc =
      coverPlateUpcs.aluminum[
        settings.aluminumColor as keyof typeof coverPlateUpcs.aluminum
      ];

    if (coverPlateUpc) {
      itemList = addItem(
        itemList,
        inventory,
        coverPlateUpc.toString(),
        totalTerminal + totalIntermediate
      );
    }
  }

  if (settings.postMaterial === "stainless-steel") {
    const totalTerminal =
      (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

    const totalIntermediate =
      (postsForRun.intermediate || 0) +
      (postsForRun.stairPostIntermediate || 0) +
      (postsForRun.stairPostTransition || 0);

    let welded = "nonwelded";

    if (
      settings.stainlessWeldType === "bottom" ||
      settings.stainlessWeldType === "both"
    ) {
      welded = "welded";
    }

    const stainlessSteel =
      coverPlateUpcs["stainless-steel"][
        settings.stainlessPostShape as keyof (typeof coverPlateUpcs)["stainless-steel"]
      ];
    const coverPlateIntermediateUpc =
      stainlessSteel.intermediate[
        welded as keyof typeof stainlessSteel.intermediate
      ];

    const coverPlateTerminalUpc =
      stainlessSteel.terminal[welded as keyof typeof stainlessSteel.terminal];

    if (coverPlateIntermediateUpc) {
      itemList = addItem(
        itemList,
        inventory,
        coverPlateIntermediateUpc.toString(),
        totalIntermediate
      );
    }

    if (coverPlateTerminalUpc) {
      itemList = addItem(
        itemList,
        inventory,
        coverPlateTerminalUpc.toString(),
        totalTerminal
      );
    }
  }

  if (settings.mountStyle === "pony-wall") {
    const totalTerminal =
      (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

    const totalIntermediate =
      (postsForRun.intermediate || 0) +
      (postsForRun.stairPostIntermediate || 0) +
      (postsForRun.stairPostTransition || 0);

    itemList = addItem(
      itemList,
      inventory,
      "SERVICE STAINLESS:SVC-Cover-Plate-FAB",
      totalTerminal + totalIntermediate
    );
  }

  return itemList;
}

export function addFasciaBrackets(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  state: Project,
  run: Run,
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  const fasciaUpcs = fasciaBracketUpcs();

  const fasciaType = settings.fasciaBracketType;
  const postType = settings.postMaterial;

  if (postType === "aluminum" || postType === "wood") {
    if (
      fasciaType === "none" ||
      fasciaType === "direct" ||
      fasciaType === "direct-spacer" ||
      fasciaType === "ss-square-4hole-rect" ||
      fasciaType === "ss-round-4hole-rect" ||
      fasciaType === "ss-round-2hole-rect" ||
      fasciaType === "ss-round-2hole-round" ||
      fasciaType === "alum-4hole-bracket" ||
      fasciaType === "alum-4hole-bracket-shims"
    ) {
      const totalPostCount = totalPosts(postsForRun);

      if (fasciaType === "direct-spacer") {
        itemList = addItem(
          itemList,
          inventory,
          fasciaDirectMountSpacerUpc().toString(),
          // 2 spacers per post.
          totalPostCount * 2
        );
      }

      // Add 2 hardware units for  mounting fascia posts.
      let quantityPerHole = 2;

      if (
        fasciaType === "ss-square-4hole-rect" ||
        fasciaType === "ss-round-4hole-rect" ||
        fasciaType === "alum-4hole-bracket-shims" ||
        fasciaType === "alum-4hole-bracket"
      ) {
        quantityPerHole = 4;
      }

      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc =
          lagUpcs[marine][
            hardwareSize as keyof (typeof lagUpcs)[typeof marine]
          ];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc.toString(),
          quantityPerHole * totalPostCount
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPostCount
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc =
          boltUpcs[marine][
            hardwareSize as keyof (typeof boltUpcs)[typeof marine]
          ];

        // 3/8" Hex bolts.
        const hexBolts38 = listOf38HexBoltUpcs();

        if (hexBolts38.includes(boltUpc) && postType === "aluminum") {
          // Add 3/8" aluminum cover.
          let color = "black";

          if (postType === "aluminum") {
            color = settings.aluminumColor;
          }

          const coverUpcs = getAluminumMountingHardwareCoverUpcs();
          const coverUpc = coverUpcs[color];

          // itemList = addItem(
          //   itemList,
          //   inventory,
          //   coverUpc,
          //   quantityPerHole * totalPostCount
          // );
        }

        if (boltUpc) {
          itemList = addItem(
            itemList,
            inventory,
            boltUpc.toString(),
            quantityPerHole * totalPostCount
          );
        }
      }
    }
  }

  if (postType === "stainless-steel") {
    const stainlessSteelPostType = settings.stainlessPostShape;
    if (fasciaType === "direct" || fasciaType === "direct-spacer" || (settings.railHeight === 'custom' && stainlessSteelPostType === '2507-square')) {
      const totalPostCount = totalPosts(postsForRun);
      
      // Add post caps to stainless systems that are direct fascia mounted.
      itemList = addStainlessPostCapsForFasciaMount(
        itemList,
        inventory,
        settings,
        postsForRun
      );
        
      itemList = addItem(
        itemList,
        inventory,
        fasciaDirectMountDrillingService(),
        totalPostCount
      );

      if (fasciaType === "direct-spacer") {
        itemList = addItem(
          itemList,
          inventory,
          fasciaDirectMountSpacerUpc().toString(),
          // 2 spacers per post.
          totalPostCount * 2
        );
      }

      // Add 2 hardware units for  mounting fascia posts.
      const quantityPerHole = 2;
      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc = lagUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc,
          quantityPerHole * totalPostCount
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPostCount
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc = boltUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          boltUpc,
          quantityPerHole * totalPostCount
        );
      }
    }

    if (
      fasciaType === "ss-round-2hole-rect" ||
      fasciaType === "ss-round-2hole-round"
    ) {
      // Add post caps to stainless systems that the bracket does not have holder at bottom.
      itemList = addStainlessPostCapsForFasciaMount(
        itemList,
        inventory,
        settings,
        postsForRun
      );
    }

    if (
      fasciaType === "ss-square-4hole-rect" ||
      fasciaType === "ss-round-4hole-rect" ||
      fasciaType === "ss-round-2hole-rect" ||
      fasciaType === "ss-round-2hole-round"
    ) {
      if (postsForRun.intermediate) {
        itemList = addItem(
          itemList,
          inventory,
          fasciaUpcs[postType][stainlessSteelPostType][fasciaType].intermediate,
          postsForRun.intermediate
        );
      }

      if (postsForRun.terminal) {
        itemList = addItem(
          itemList,
          inventory,
          fasciaUpcs[postType][stainlessSteelPostType][fasciaType].terminal,
          postsForRun.terminal
        );
      }

      if (run.stairs) {
        if (postsForRun.stairPostTransition) {
          itemList = addItem(
            itemList,
            inventory,
            fasciaUpcs[postType][stainlessSteelPostType][fasciaType]
              .intermediate,
            postsForRun.stairPostTransition
          );
        }

        if (postsForRun.stairPostIntermediate) {
          itemList = addItem(
            itemList,
            inventory,
            fasciaUpcs[postType][stainlessSteelPostType][fasciaType]
              .intermediate,
            postsForRun.stairPostIntermediate
          );
        }

        if (postsForRun.stairPostTerminal) {
          itemList = addItem(
            itemList,
            inventory,
            fasciaUpcs[postType][stainlessSteelPostType][fasciaType].terminal,
            postsForRun.stairPostTerminal
          );
        }
      }

      let quantityPerHole = 4;
      // Add hardware for mounting.
      if (
        fasciaType === "ss-square-4hole-rect" ||
        fasciaType === "ss-round-4hole-rect"
      ) {
        quantityPerHole = 4;
      }

      if (
        fasciaType === "ss-round-2hole-rect" ||
        fasciaType === "ss-round-2hole-round"
      ) {
        quantityPerHole = 2;
      }

      let totalPosts =
        (postsForRun.terminal || 0) + (postsForRun.intermediate || 0);

      if (postsForRun.stairPostIntermediate) {
        totalPosts += postsForRun.stairPostIntermediate;
      }

      if (postsForRun.stairPostTerminal) {
        totalPosts += postsForRun.stairPostTerminal;
      }

      if (postsForRun.stairPostTransition) {
        totalPosts += postsForRun.stairPostTransition;
      }

      const { hardwareType, hardwareSize } = settings;

      // 316 is marine grade, 304 is normal grade.
      const marine = isMarine(settings) ? "316" : "304";

      if (hardwareType === "lags") {
        const lagUpcs = lagHardwareUpcs();
        const lagUpc = lagUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          lagUpc,
          quantityPerHole * totalPosts
        );
      }

      if (hardwareType === "concrete") {
        if (hardwareType === "concrete") {
          const concreteUpcs = concreteHardwareUpcs();
          const { concreteHardwareType, concreteHardwareSize } = settings;
          const concreteUpc =
            concreteUpcs[concreteHardwareType][concreteHardwareSize];

          itemList = addItem(
            itemList,
            inventory,
            concreteUpc,
            quantityPerHole * totalPosts
          );
        }
      }

      if (hardwareType === "bolts") {
        const boltUpcs = boltHardwareUpcs();
        const boltUpc = boltUpcs[marine][hardwareSize];
        itemList = addItem(
          itemList,
          inventory,
          boltUpc,
          quantityPerHole * totalPosts
        );
      }
    }
  }

  return itemList;
}

export function addStairWashers(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  state: Project,
  run: Run,
  postsForRun: Record<string, number>
) {
  if (!run.stairs) {
    return itemList;
  }

  const stairs = state.stairs.get(run.stairs.stairsIndex);
  const { settings } = state;

  if (stairs) {
    const angle = stairs.angle;

    const washerAngle = getWasherAngle(angle);

    let cableSize = "";

    if (state.settings.cableSize === "1 x 19 - 1/8”") {
      cableSize = "6mm";
    }

    if (
      state.settings.cableSize === "1 x 19 - 3/16”" ||
      state.settings.cableSize === "7 x 7 - 3/16”"
    ) {
      cableSize = "8mm";
    }

    let postStyle: "flat" | "radius" = "flat";

    if (
      state.settings.stainlessPostShape === "round" &&
      state.settings.postMaterial === "stainless-steel"
    ) {
      postStyle = "radius";
    }

    const numCables = getNumberOfCableRuns(settings);

    const quantityPerPost = numCables;

    let stairWashers = {};

    if (state.settings.cableType === "2507-steel") {
      stairWashers = get2507StairWashers();
    } else {
      stairWashers = getStairWashers();
    }

    let stairWasherUpc =
      stairWashers.washers[postStyle][washerAngle][cableSize];

    if (
      state.settings.cableFittingType === "thru-post" ||
      isFinelineBallButtonFittings(state.settings)
    ) {
      stairWasherUpc = stairWashers.washers[postStyle][washerAngle][cableSize];

      if (isMarine(settings) && settings.postMaterial === "aluminum") {
        const nylonStairWasherUpcs = getNylonStairWasherUpcs();
        stairWasherUpc = nylonStairWasherUpcs[settings.cableSize];
      }
    }

    if (
      state.settings.cableFittingType === "swageless" ||
      isLoProfileFittings(state.settings)
    ) {
      stairWasherUpc = stairWashers.swageless[postStyle][washerAngle];

      if (isMarine(settings) && settings.postMaterial === "aluminum") {
        const nylonStairWasherUpcs = getNylonStairWasherUpcs();
        stairWasherUpc = nylonStairWasherUpcs[settings.cableSize];
      }
    }

    if (stairWasherUpc) {
      // Aluminum splice for round and shaped.
      const numberOfWashers =
        quantityPerPost * (postsForRun.stairPostTerminal || 0);

      if (numberOfWashers) {
        itemList = addItem(
          itemList,
          inventory,
          stairWasherUpc,
          numberOfWashers
        );
      }
    }
  }

  return itemList;
}

function isLoProfileFittings(settings: ProjectSettings) {
  return (
    settings.cableFittingType === "thru-post" &&
    settings.thruPostType === "low-profile"
  );
}

function isFinelineBallButtonFittings(settings: ProjectSettings) {
  return (
    settings.cableFittingType === "thru-post" &&
    (settings.thruPostType === "fineline-ball" ||
      settings.thruPostType === "fineline-button" ||
      settings.thruPostType === "classic-ball" ||
      settings.thruPostType === "classic-button")
  );
}

function getWasherAngle(angle: number) {
  if (angle >= 33 && angle < 36) {
    return 35;
  }

  if (angle >= 36) {
    return 38;
  }

  if (angle >= 30 && angle < 33) {
    return 32;
  }

  if (angle >= 20 && angle < 30) {
    return 28;
  }

  if (angle <= 5) {
    return 5;
  }

  if (angle > 5 && angle < 20) {
    return 5;
  }

  return 5;
}

export function handleOverrides(
  itemList: ItemListType,
  object: any,
  inventoryList: AllInventoryTypes[]
) {
  if (
    object.overrides &&
    object.overrides.itemListChanges &&
    object.overrides.itemListChanges.size
  ) {
    object.overrides.itemListChanges.forEach((item) => {
      if (item.type === "quantityChange") {
        if (itemList[item.product.upc]) {
          itemList[item.product.upc].quantity = item.newQuantity;
          itemList[item.product.upc].total = roundToHundreth(
            itemList[item.product.upc].quantity *
              itemList[item.product.upc].price
          );
        }
      }

      if (item.type === "itemRemoved") {
        const upc = item.product.BarCodeValue || item.product.upc;

        delete itemList[upc];
      }

      if (item.type === "removeGroupItem") {
        const upc = item.id;
        const group = item.group;

        if (itemList[upc]) {
          // If group is present remove the quantity of the item, if the quantity ends up at 0 or below delete item from item list.
          // Remove the item from the parts list.
          const quantity = itemList[upc].quantity;

          if (
            group.product.ItemGroupLine &&
            group.product.ItemGroupLine.length
          ) {
            const matchingProduct = group.product.ItemGroupLine.find((item) => {
              const theItem = findProductByFullName(
                item?.ItemRef?.FullName,
                inventoryList
              );

              let theUpc = null;

              if (theItem) {
                theUpc =
                  theItem.BarCodeValue || theItem.upc || theItem.FullName;
              }

              return theUpc === upc;
            });

            if (matchingProduct) {
              itemList[upc].quantity =
                quantity - group.quantity * matchingProduct.Quantity;

              if (itemList[upc].quantity <= 0) {
                delete itemList[upc];
              }
            }
          }
        }
      }

      if (item.type === "itemAdded") {
        if (item.product.type === "group") {
          item.product.SalesDesc = item.product.FullName;
          const groupItems = item.product.ItemGroupLine;

          // Do not add group line item to parts list.
          // const upc =
          //   item.product.BarCodeValue ||
          //   item.product.upc ||
          //   item.product.FullName;
          // if (!itemList[upc]) {
          //   const price = parseFloat(item.product.SalesPrice) || 0;

          //   itemList[upc] = {
          //     quantity: item.quantity,
          //     measure: "ea",
          //     upc: upc,
          //     description: item.product.SalesDesc,
          //     name: item.product.Name,
          //     price: price,
          //     total: roundToHundreth(item.quantity * price),
          //     type: item.product.type,
          //     addedItem: true,
          //   };
          // } else {
          //   itemList[upc].quantity += item.quantity;
          //   itemList[upc].total = roundToHundreth(
          //     itemList[upc].quantity * itemList[upc].price
          //   );
          // }

          if (groupItems && groupItems.length) {
            groupItems.forEach((groupItem) => {
              const product = findProductByFullName(
                groupItem?.ItemRef?.FullName,
                inventoryList
              );

              if (product) {
                const upc =
                  product.BarCodeValue || product.upc || product.FullName;

                if (!itemList[upc]) {
                  const price = parseFloat(product.SalesPrice) || 0;

                  itemList[upc] = {
                    quantity:
                      item.quantity * parseFloat(groupItem.Quantity || 0, 10),
                    measure: getUnitOfMeasure(groupItem.UnitOfMeasure),
                    upc: upc,
                    description: product.SalesDesc,
                    name: product.Name,
                    price: price,
                    total: roundToHundreth(
                      item.quantity *
                        parseFloat(groupItem.Quantity || 0, 10) *
                        price
                    ),
                    addedItem: true,
                  };
                } else {
                  itemList[upc].quantity +=
                    item.quantity * parseFloat(groupItem.Quantity || 0, 10);
                  itemList[upc].total = roundToHundreth(
                    itemList[upc].quantity * itemList[upc].price
                  );
                }
              }
            });
          }
        } else {
          const upc = item.product.BarCodeValue || item.product.upc;
          if (!itemList[upc]) {
            const price = parseFloat(item.product.SalesPrice) || 0;

            itemList[upc] = {
              quantity: item.quantity,
              measure: "ea",
              upc: upc,
              description: item.product.SalesDesc,
              name: item.product.Name,
              price: price,
              type: item.product.type,
              total: roundToHundreth(item.quantity * price),
              addedItem: true,
            };
          } else {
            itemList[upc].quantity += item.quantity;
            itemList[upc].total = roundToHundreth(
              itemList[upc].quantity * itemList[upc].price
            );
          }
        }
      }
    });
  }

  return itemList;
}

export function addMountingHardwareCovers(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  if (
    settings.postMaterial === "customer-provided"
  ) {
    return itemList;
  }

  if (settings.postMaterial !== "aluminum") {
    return itemList;
  }

  let color = "black";

  if (settings.postMaterial === "aluminum") {
    color = settings.aluminumColor;
  }

  const coverUpcs = getAluminumMountingHardwareCoverUpcs();
  const coverUpc = coverUpcs[color as keyof typeof coverUpcs].toString();

  if (coverUpc) {
    let cover = findByUpc(coverUpc, inventory);

    if (cover) {
      const coverMapped = mapQBObjectToJS(cover);
      // 4 hardware units per post.
      const intermediate = postsForRun?.intermediate || 0;
      const terminal = postsForRun?.terminal || 0;
      const stairPostIntermediate = postsForRun?.stairPostIntermediate || 0;
      const stairPostTerminal = postsForRun?.stairPostTerminal || 0;
      const stairPostTransition = postsForRun?.stairPostTransition || 0;
      let multiplier = settings.mountStyle === "fascia" ? 2 : 4;
      if ( settings.mountStyle === 'fascia' &&
        ( settings.fasciaBracketType === 'ss-square-4hole-rect' ||
        settings.fasciaBracketType === 'ss-round-4hole-rect' ||
        settings.fasciaBracketType === 'alum-4hole-bracket-shims' ||
        settings.fasciaBracketType === 'alum-4hole-bracket'
      )) {
        multiplier = 4;
      }
      const numberOfHardware =
        (intermediate +
          terminal +
          stairPostIntermediate +
          stairPostTerminal +
          stairPostTransition) *
        multiplier;
      

      if (!itemList[coverUpc]) {
        itemList[coverUpc] = {
          quantity: numberOfHardware,
          measure: "ea",
          upc: coverUpc,
          description: coverMapped.description,
          name: coverMapped.name,
          price: coverMapped.price || 0,
          type: "assembly",
          total: roundToHundreth(numberOfHardware * (coverMapped.price || 0)),
        };
      } else {
        itemList[coverUpc].quantity += numberOfHardware;
        itemList[coverUpc].total = roundToHundreth(
          numberOfHardware * (coverMapped.price || 0) +
            (itemList[coverUpc]?.total || 0)
        );
      }
    }
  }

  return itemList;
}

export function addMountingHardware(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  const {
    hardwareType,
    hardwareSize,
    concreteHardwareType,
    concreteHardwareSize,
  } = settings;

  if (settings.postMaterial === "customer-provided") {
    return itemList;
  }

  if (settings.mountStyle === "fascia") {
    return itemList;
  }

  // 316 is marine grade, 304 is normal grade.
  const marine = isMarine(settings) ? "316" : "304";

  if (hardwareType === "lags") {
    const lagUpcs = lagHardwareUpcs();
    const lagUpc = lagUpcs[marine][hardwareSize];

    if (lagUpc) {
      let lag = findByUpc(lagUpc, inventory);

      if (lag) {
        const lagMapped = mapQBObjectToJS(lag);
        // 4 hardware units per post.
        const intermediate = postsForRun?.intermediate || 0;
        const terminal = postsForRun?.terminal || 0;
        const stairPostIntermediate = postsForRun?.stairPostIntermediate || 0;
        const stairPostTerminal = postsForRun?.stairPostTerminal || 0;
        const stairPostTransition = postsForRun?.stairPostTransition || 0;
        const numberOfHardware =
          (intermediate +
            terminal +
            stairPostIntermediate +
            stairPostTerminal +
            stairPostTransition) *
          4;

        if (!itemList[lagUpc]) {
          itemList[lagUpc] = {
            quantity: numberOfHardware,
            measure: "ea",
            upc: lagUpc,
            description: lagMapped.description,
            name: lagMapped.name,
            price: lagMapped.price || 0,
            type: "inventory",
            total: roundToHundreth(numberOfHardware * (lagMapped.price || 0)),
          };
        } else {
          itemList[lagUpc].quantity += numberOfHardware;
          itemList[lagUpc].total = roundToHundreth(
            numberOfHardware * (lagMapped.price || 0) +
              (itemList[lagUpc]?.total || 0)
          );
        }
      }
    }
  }

  if (hardwareType === "bolts") {
    const boltUpcs = boltHardwareUpcs();
    const boltUpc = boltUpcs[marine][hardwareSize];

    if (boltUpc) {
      let bolt = findByUpc(boltUpc, inventory);

      if (bolt) {
        const boltMapped = mapQBObjectToJS(bolt);
        // 4 hardware units per post.
        const intermediate = postsForRun?.intermediate || 0;
        const terminal = postsForRun?.terminal || 0;
        const stairPostIntermediate = postsForRun?.stairPostIntermediate || 0;
        const stairPostTerminal = postsForRun?.stairPostTerminal || 0;
        const stairPostTransition = postsForRun?.stairPostTransition || 0;
        const numberOfHardware =
          (intermediate +
            terminal +
            stairPostIntermediate +
            stairPostTerminal +
            stairPostTransition) *
          4;

        if (!itemList[boltUpc]) {
          itemList[boltUpc] = {
            quantity: numberOfHardware,
            measure: "ea",
            upc: boltUpc,
            description: boltMapped.description,
            name: boltMapped.name,
            price: boltMapped.price || 0,
            type: "inventory",
            total: roundToHundreth(numberOfHardware * (boltMapped.price || 0)),
          };
        } else {
          itemList[boltUpc].quantity += numberOfHardware;
          itemList[boltUpc].total = roundToHundreth(
            numberOfHardware * (boltMapped.price || 0) +
              (itemList[boltUpc]?.total || 0)
          );
        }
      }
    }
  }

  if (hardwareType === "concrete") {
    const concreteUpcs = concreteHardwareUpcs();
    const concreteUpc =
      concreteUpcs[concreteHardwareType][concreteHardwareSize];

    if (concreteUpc) {
      let concrete = findByUpc(concreteUpc, inventory);

      if (concrete) {
        const concreteMapped = mapQBObjectToJS(concrete);
        // 4 hardware units per post.
        const intermediate = postsForRun?.intermediate || 0;
        const terminal = postsForRun?.terminal || 0;
        const stairPostIntermediate = postsForRun?.stairPostIntermediate || 0;
        const stairPostTerminal = postsForRun?.stairPostTerminal || 0;
        const stairPostTransition = postsForRun?.stairPostTransition || 0;
        const numberOfHardware =
          (intermediate +
            terminal +
            stairPostIntermediate +
            stairPostTerminal +
            stairPostTransition) *
          4;

        if (!itemList[concreteUpc]) {
          itemList[concreteUpc] = {
            quantity: numberOfHardware,
            measure: "ea",
            upc: concreteUpc,
            description: concreteMapped.description,
            name: concreteMapped.name,
            price: concreteMapped.price,
            type: "inventory",
            total: roundToHundreth(
              numberOfHardware * (concreteMapped?.price || 0)
            ),
          };
        } else {
          itemList[concreteUpc].quantity += numberOfHardware;
          itemList[concreteUpc].total = roundToHundreth(
            numberOfHardware * (concreteMapped?.price || 0) +
              (itemList[concreteUpc]?.total || 0)
          );
        }
      }
    }

    const concreteHexUpcs = concreteHardwareHexboltUpcs();

    const concreteHexUpc = concreteHexUpcs[concreteHardwareType][concreteHardwareSize];

    if ( concreteHexUpc ) {
      // 4 hardware units per post.
      const intermediate = postsForRun?.intermediate || 0;
      const terminal = postsForRun?.terminal || 0;
      const stairPostIntermediate = postsForRun?.stairPostIntermediate || 0;
      const stairPostTerminal = postsForRun?.stairPostTerminal || 0;
      const stairPostTransition = postsForRun?.stairPostTransition || 0;
      const numberOfHardware =
        (intermediate +
          terminal +
          stairPostIntermediate +
          stairPostTerminal +
          stairPostTransition) *
        4;

      addItem(itemList, inventory, concreteHexUpc, numberOfHardware);
    }
  }

  return itemList;
}

export function calculateRawPostFootage(
  quantity: number,
  railHeight: "custom" | "36" | "42"
) {
  if (railHeight === "36") {
    return roundToHundreth(3 * quantity);
  } else if (railHeight === "42") {
    return roundToHundreth(3.5 * quantity);
  } else if (railHeight === "custom") {
    return roundToHundreth(3.5 * quantity);
  } else {
    return roundToHundreth(3 * quantity);
  }
}

export function getCableForRun(
  railDistance: number,
  settings: ProjectSettings,
  cables: Record<string, number>,
  inventory: InventoryType,
  cableSize: string
) {
  const potentialUpc = cables[cableSize];

  if (!potentialUpc || !potentialUpc.toString) {
    return null;
  }

  const upc = potentialUpc.toString();

  const item = findByUpc(upc, inventory);

  if (!item || !potentialUpc) {
    return null;
  }

  const data = mapQBObjectToJS(item);

  const cableForRun = {
    quantity: calculateRailingAmount(railDistance, settings),
    upc: upc,
    measure: data.measure || "ft",
    description: data.description || "",
    name: data.name || "",
    price: data.price || 0,
    type: "inventory",
  };

  return cableForRun;
}

export function getPostAttributeByMaterial(
  postMaterial: string,
  options: {
    aluminumColor: string;
    stainlessPostShape: string;
    woodType: string;
  }
) {
  let postAttribute = options.aluminumColor;

  if (postMaterial === "aluminum") {
    postAttribute = options.aluminumColor;
  }

  if (postMaterial === "stainless-steel") {
    postAttribute = options.stainlessPostShape;
  }

  if (postMaterial === "wood") {
    postAttribute = options.woodType;
  }

  return postAttribute;
}

export function totalPosts(postsForRun: Record<string, number>) {
  return Object.values(postsForRun).reduce((sum, count) => sum + count, 0);
}

export function getTotalRailDistanceOfStairsRun(run: Run, state: Project) {
  const posts = getPosts(run, state.settings, state);

  const groups = getGroupedPostsForRun(posts, run);

  let length = 0;

  Object.entries(groups).forEach(([key, value]) => {
    if (value.length < 2) {
      return;
    }

    const { distance } = getDistancesFromPostGroupsForRun(
      run,
      state.stairs,
      value,
      key
    );

    length += distance;
  });

  return length;
}

export function getRailDistance(
  run: Run,
  stairs: Map<string, Stairs>,
  coordinates = null
) {
  if (!run) {
    return 0;
  }

  let point1 = { x: run.x1, y: run.y1 };
  let point2 = { x: run.x2, y: run.y2 };

  if (coordinates) {
    const { x1, y1, x2, y2 } = coordinates;
    point1 = { x: x1, y: y1 };
    point2 = { x: x2, y: y2 };
  }

  const runStairs = run.stairs as any;
  if (runStairs) {
    if (runStairs.snapped) {
      const theStairs = stairs.get(runStairs.stairsIndex);

      if (theStairs) {
        const { startPost, endPost } = runStairs;

        if (theStairs) {
          let distanceToStartPost = 0;
          let distancePastEndPost = 0;

          let side = 0;
          if (isPointBetweenPoints(point1, endPost, startPost)) {
            if (isPointBetweenPoints(startPost, point1, point2)) {
              distanceToStartPost = getDistance(point2, startPost);
            }

            if (isPointBetweenPoints(endPost, point1, point2)) {
              distancePastEndPost = getDistance(endPost, point2);
            }
          } else if (
            !isPointBetweenPoints(
              point1,
              (run?.stairs as any)?.snapLineCorners[runStairs.keys.start],
              (run?.stairs as any)?.snapLineCorners[runStairs.keys.end]
            ) &&
            !isPointBetweenPoints(
              point2,
              runStairs.snapLineCorners[runStairs.keys.start],
              runStairs.snapLineCorners[runStairs.keys.end]
            ) &&
            !isPointBetweenPoints(
              runStairs.snapLineCorners[runStairs.keys.end],
              { x: run.x1, y: run.y1 },
              { x: run.x2, y: run.y2 }
            ) &&
            !isPointBetweenPoints(
              runStairs.snapLineCorners[runStairs.keys.start],
              { x: run.x1, y: run.y1 },
              { x: run.x2, y: run.y2 }
            )
          ) {
            distanceToStartPost = getDistance(point1, point2);
          } else {
            if (isPointBetweenPoints(startPost, point1, point2)) {
              distanceToStartPost = getDistance(point1, startPost);
            }

            if (isPointBetweenPoints(endPost, point1, point2)) {
              distancePastEndPost = getDistance(endPost, point2);
            }
          }

          side = getHypotenuseInFeetRaw(run, theStairs, run.stairs);

          const angle = theStairs.angle * (Math.PI / 180);

          // Hypotenuse.
          const hypotenuse = side / Math.cos(angle);

          return hypotenuse + distancePastEndPost + distanceToStartPost;
        }
      }
    } else {
      const theStairs = stairs.get(runStairs.stairsIndex);
      if (theStairs) {
        const endPost = runStairs.endPost;

        const distancePastEndPost = getDistance(endPost, point2);

        let side;

        if (theStairs.orientation === "vertical") {
          side = Math.abs(theStairs.y2 - theStairs.y1);
        } else {
          side = Math.abs(theStairs.x2 - theStairs.x1);
        }
        const angle = theStairs.angle * (Math.PI / 180);

        // Hypotenuse.
        const hypotenuse = side / Math.cos(angle);

        return hypotenuse + distancePastEndPost;
      }
    }
  }

  return getDistance(point1, point2);
}

export function getItemListCableForRun(cableForRun: {
  quantity: number;
  upc: string;
  measure: string;
  description: string;
  name: string;
  price: number;
  type: string;
}): Item {
  cableForRun.upc = cableForRun.upc.toString();
  return {
    quantity: cableForRun.quantity,
    measure: cableForRun.measure,
    upc: cableForRun.upc,
    description: cableForRun.description,
    name: cableForRun.name,
    price: cableForRun.price,
    type: "inventory",
    total: roundToHundreth(cableForRun.quantity * cableForRun.price),
  };
}

export function addFittings(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  railDistance: number,
  run: Run,
  postsForRun: Record<string, number>,
  state: Project
) {
  let fittingAssembly: any;

  const postTypes = {
    wood: "wood",
    aluminum: "metal",
    "stainless-steel": "metal",
  };
  const postType =
    postTypes[settings.postMaterial as keyof typeof postTypes] || "metal";

  if (settings.cableType === "black-oxide") {
    const blackOxideFittings = getBlackOxideFittingsItems();

    const blackOxideType =
      settings.blackOxideType === "use-bundles" ? "bundles" : "kits";

    fittingAssembly =
      blackOxideFittings[blackOxideType][settings.cableSize][postType][
        settings.cableFittingType
      ];
  } else if (settings.cableType === "2507-steel") {
    const cablesFittings = get2507FittingsUpcs();

    fittingAssembly =
      cablesFittings[settings.cableSize][postType][settings.cableFittingType];
  } else {
    const cablesFittings = getFittingsItems();

    fittingAssembly =
      cablesFittings[settings.cableSize][postType][settings.cableFittingType];
  }

  const stateCorners = state.corners;

  let tensioner = "singleTensioner";

  // Stairs that go from angled to straight use double tensioner.
  if ((postsForRun.stairPostTransition || 0) > 0) {
    tensioner = "doubleTensioner";
  }

  // Greater than 50ft use double tensioner.
  if (railDistance > 1200) {
    tensioner = "doubleTensioner";
  }

  if (run.getIn(["settings", "doubleTensioner"]) === "doubleTensioner") {
    tensioner = "doubleTensioner";
  }

  let doNotAddFittings = false;

  if (stateCorners.size) {
    stateCorners.forEach((corner) => {
      if (
        corner.id.has(run.id) &&
        state.fittingsCornersSeen &&
        state.fittingsCornersSeen[corner.identity]
      ) {
        doNotAddFittings = true;
      }
    });
  }

  if (stateCorners.size) {
    stateCorners.forEach((corner) => {
      if (corner.id.has(run.id) && corner.cableContinuesThrough) {
        if (!state.fittingsCornersSeen) {
          state.fittingsCornersSeen = {};
        }

        state.fittingsCornersSeen[corner.identity] = true;

        tensioner = "doubleTensioner";
      }
    });
  }

  // Important to keep this check after teh corner check for adding new corners.
  if (doNotAddFittings) {
    return itemList;
  }

  if (settings.cableFittingType === "thru-post") {
    fittingAssembly = fittingAssembly[settings.thruPostType];

    if (settings.thruPostType === "factory-swaged") {
      fittingAssembly =
        fittingAssembly[settings.factorySwaged][tensioner][settings.endFitting];
    } else if (settings.thruPostType === "fineline-ball") {
      fittingAssembly = fittingAssembly[settings.finelineSwaged];
    } else if (settings.thruPostType === "fineline-button") {
      fittingAssembly = fittingAssembly[settings.finelineSwaged];
    } else if (settings.thruPostType === "classic-ball") {
      fittingAssembly = fittingAssembly[settings.finelineSwaged];
    } else if (settings.thruPostType === "classic-button") {
      fittingAssembly = fittingAssembly[settings.finelineSwaged];
    } else if (settings.thruPostType === "field-swaged") {
      fittingAssembly = fittingAssembly[tensioner][settings.endFitting];
    } else if (settings.thruPostType === "low-profile") {
      fittingAssembly = fittingAssembly[settings.receiverStudSize];
    } else {
      fittingAssembly = 0;
    }
  }

  if (settings.cableFittingType === "swageless") {
    const cableColor = "normal";

    if (settings.swagelessType === "type-1") {
      fittingAssembly = fittingAssembly[settings.swagelessType][cableColor];
    }

    if (settings.swagelessType === "type-2") {
      const receiverStudSize = settings.receiverStudSize;

      fittingAssembly =
        fittingAssembly[settings.swagelessType][receiverStudSize];
    }
  }

  if (settings.cableFittingType === "surface-mount") {
    fittingAssembly = fittingAssembly[tensioner];

    const surfaceMountInstallKits = getSurfaceMountInstallKitUpcs();
    if (surfaceMountInstallKits[settings.cableSize]) {
      itemList = addItemForceQuantity(
        itemList,
        inventory,
        surfaceMountInstallKits[settings.cableSize],
        1
      );
    }
  }

  if (
    fittingAssembly &&
    fittingAssembly.type === "fittingsGroup" &&
    fittingAssembly.items.length
  ) {
    fittingAssembly.items.forEach((item) => {
      let upc = item.upc;

      const numCables = getNumberOfCableRuns(settings);
      const quantity = numCables;

      // Add nylon washers when near saltwater in addition to regular washer.
      if (isMarine(settings)) {
        if (isFittingWasherUpc(upc)) {
          const nylonWasherUpcs = getNylonWasherUpcs();

          const nylonWasherUpc = nylonWasherUpcs[settings.cableSize];

          itemList = addItem(
            itemList,
            inventory,
            nylonWasherUpc,
            quantity * parseInt(item.quantity, 10)
          );
        }
      }

      itemList = addItem(
        itemList,
        inventory,
        upc,
        quantity * parseInt(item.quantity, 10)
      );
    });
  }

  if (run.getIn(["endPosts", "start"])) {
    // Add phantom post fittings.
  }

  return itemList;
}

export function getNumberOfToprailSplices(distance: number) {
  const toprailDivisor = 20;
  const numberOfSplices = Math.floor(distance / toprailDivisor);

  if (distance !== 0 && distance % 20 === 0) {
    // When exactly on 20 ft do not add a splice.
    return numberOfSplices - 1;
  }

  return numberOfSplices;
}

export function getQuantityScrewsPerRun(postsForRun: Record<string, number>) {
  const screws =
    (postsForRun.intermediate || 0) * 4 +
    (postsForRun.terminal || 0) * 4 +
    (postsForRun.stairPostTransition || 0) * 4 +
    (postsForRun.stairPostTerminal || 0) * 4 +
    (postsForRun.stairPostIntermediate || 0) * 4;

  return screws;
}

export function mapQBObjectToJS(QBObject: AllInventoryTypes): MappedQBObject {
  let measure = "ea";

  if ((QBObject as any)?.UnitOfMeasureSetRef) {
    if (
      (QBObject as any)?.UnitOfMeasureSetRef.FullName === "Length by the foot"
    ) {
      measure = "ft";
    }
  }

  let upc: string | undefined = undefined;
  if (typeof QBObject.BarCodeValue === "number") {
    upc = QBObject.BarCodeValue.toString();
  } else if (typeof QBObject.BarCodeValue === "string") {
    upc = QBObject.BarCodeValue;
  }

  return {
    measure: measure,
    upc: upc,
    description: QBObject.SalesDesc || undefined,
    name: QBObject.FullName,
    price: QBObject.SalesPrice || undefined,
    type: QBObject.type,
  };
}

export const findByUpc = (
  (found: Record<string, AllInventoryTypes>) =>
  (upc: string, inventory: AllInventoryTypes[]) => {
    if (!upc || !upc.toString) {
      return null;
    }

    if (found[upc]) {
      return found[upc];
    }

    if (inventory && inventory.find) {
      if (upc.toString().startsWith("custompart")) {
        const foundItem = inventory.find(
          (item) => customPartUpc() === item.BarCodeValue?.toString()
        );

        if (foundItem) {
          found[upc] = foundItem;
          return foundItem;
        }
      } else {
        const foundItem = inventory.find(
          (item) =>
            upc.toString() === item?.BarCodeValue?.toString() ||
            upc.toString() === item?.upc?.toString()
        );

        if (foundItem) {
          found[upc] = foundItem;
          return foundItem;
        }
      }
    }

    return null;
  }
)({});

export function customPartUpc() {
  return "QB:01033918053365";
}

function runIndexToLetter(index: number) {
  const map = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
    8: "I",
    9: "J",
    10: "K",
    11: "L",
    12: "M",
    13: "N",
    14: "O",
    15: "P",
    16: "Q",
    17: "R",
    18: "S",
    19: "T",
    20: "U",
    21: "V",
    22: "W",
    23: "X",
    24: "Y",
    25: "Z",
    26: "a",
    27: "b",
    28: "c",
    29: "d",
    30: "e",
    31: "f",
    32: "g",
    33: "h",
    34: "i",
    35: "j",
    36: "k",
    37: "l",
    38: "m",
    39: "n",
    40: "o",
    41: "p",
    42: "q",
    43: "r",
    44: "s",
    45: "t",
    46: "u",
    47: "v",
    48: "w",
    49: "x",
    50: "y",
    51: "z",
  };

  return map[index as keyof typeof map];
}

function fittingsNiceName() {
  const names = {
    // Standard Tensioners.
    // 1/8"
    616320732042: {
      name: "Field Tensioner",
    },
    616320732066: {
      name: "Field Terminal",
    },
    616320731984: {
      name: "Factory Tensioner",
    },
    616320732004: {
      name: "Factory Terminal",
    },
    616320730253: {
      name: "Acorn Nut",
    },
    616320730284: {
      name: "Hex Nut",
    },
    616320730307: {
      name: "Washer",
    },
    // 3/16"
    616320732059: {
      name: "Field Tensioner",
    },
    616320732073: {
      name: "Field Terminal",
    },
    616320731991: {
      name: "Factory Tensioner",
    },
    616320732011: {
      name: "Factory Terminal",
    },
    616320730260: {
      name: "Acorn Nut",
    },
    616320730277: {
      name: "Hex Nut",
    },
    616320730314: {
      name: "Washer",
    },
    // Fineline Ball
    // 1/8"
    616320735029: {
      name: "Fineline Ball Turnbuckle",
    },
    616453985803: {
      name: "Fineline Ball End Field",
    },
    616453985780: {
      name: "Fineline Ball End Factory",
    },
    // 3/16"
    616320735074: {
      name: "Fineline Ball Turnbuckle",
    },
    616453985810: {
      name: "Ball End Field",
    },
    616453985797: {
      name: "Fineline Ball End Factory",
    },
    "SERVICE CABLE:SVC-Swaging": {
      name: "Swaging Service",
    },
    // Fineline Button
    // 1/8"
    616320735036: {
      name: "Fineline Button Turnbuckle",
    },
    616453985841: {
      name: "Fineline Button End Field",
    },
    616453985827: {
      name: "Fineline Button End Factory",
    },
    // 3/16"
    616320735043: {
      name: "Fineline Button Turnbuckle",
    },
    616453985858: {
      name: "Button End Field",
    },
    616453985834: {
      name: "Button End Factory",
    },
    // Classic Ball
    // 3/16"
    616320735050: {
      name: "Classic Ball Turnbuckle",
    },
    // 616453985810: {
    //   name: "Ball End Field",
    // },
    // 616453985797: {
    //   name: "Ball End Factory",
    // },
    // Classic Button
    // 3/16"
    616320735067: {
      name: "Classic Button Turnbuckle",
    },
    // 616453985858: {
    //   name: "Button End Field",
    // },
    // 616453985834: {
    //   name: "Button End Factory",
    // },
    // Low Profile
    // 1/8"
    616320735005: {
      name: "Low Profile Short Stud",
    },
    616453982062: {
      name: "Low Profile Long Stud",
    },
    // Quick Nut
    // 1/8"
    616320731755: {
      name: "Quick Nut",
    },
    616320731779: {
      name: "Quick Nut Cover",
    },
    // 3/16"
    616320731762: {
      name: "Quick Nut",
    },
    616320731786: {
      name: "Quick Nut Cover",
    },
    662187011837: {
      name: "Nylon Washer",
    },
    616320730932: {
      name: "Nylon Washer",
    },
    662187011820: {
      name: "Nylon Stair Washer",
    },
  };

  const stairWashers = getStairWashers();

  Object.entries(stairWashers.washers).forEach(([type, angleType]) => {
    const typeName = {
      flat: "Flat",
      radius: "Radius",
    };
    Object.entries(angleType).forEach(([angle, measures]) => {
      Object.entries(measures).forEach(([measure, upc]) => {
        names[upc.toString() as keyof typeof names] = {
          name: `${
            typeName[type as keyof typeof typeName]
          } ${angle}° ${measure} Stair Washer`,
        };
      });
    });
  });

  Object.entries(stairWashers.swageless).forEach(([type, angleType]) => {
    const typeName = {
      flat: "Flat",
      radius: "Radius",
    };
    Object.entries(angleType).forEach(([angle, upc]) => {
      names[upc.toString() as keyof typeof names] = {
        name: `${
          typeName[type as keyof typeof typeName]
        } ${angle}° Swageless Stair Washer`,
      };
    });
  });

  const stairWashers2507 = get2507StairWashers();

  Object.entries(stairWashers2507.washers).forEach(([type, angleType]) => {
    const typeName = {
      flat: "Flat",
      radius: "Radius",
    };
    Object.entries(angleType).forEach(([angle, measures]) => {
      Object.entries(measures).forEach(([measure, upc]) => {
        names[upc.toString() as keyof typeof names] = {
          name: `${
            typeName[type as keyof typeof typeName]
          } ${angle}° ${measure} Stair Washer`,
        };
      });
    });
  });

  Object.entries(stairWashers2507.swageless).forEach(([type, angleType]) => {
    const typeName = {
      flat: "Flat",
      radius: "Radius",
    };
    Object.entries(angleType).forEach(([angle, upc]) => {
      names[upc.toString() as keyof typeof names] = {
        name: `${
          typeName[type as keyof typeof typeName]
        } ${angle}° Swageless Stair Washer`,
      };
    });
  });

  return names;
}

function getFittingsForRun(runItemList: ItemListType) {
  const map = fittingsNiceName();
  const list: { upc: string; name: string; quantity: number }[] = [];

  Object.values(runItemList).forEach((item) => {
    if ((map as any)?.[item.upc]) {
      list.push({
        upc: item.upc,
        name: (map as any)[item.upc].name,
        quantity: item.quantity,
      });
    }
  });

  return list;
}

function getExtrasForRun(runItemList, run) {
  let extras = { items: [] };

  if (run.overrides.itemListChanges) {
    run.overrides.itemListChanges.forEach((override) => {
      if (override.type === "itemAdded") {
        if (override.product) {
          if (override.product.upc) {
            const part = runItemList[override.product.upc];

            extras.items.push({
              upc: part.upc,
              name: part.upc,
              quantity: part.quantity,
            });
          }

          if (override.product.BarCodeValue) {
            const part = runItemList[override.product.BarCodeValue];

            if (part) {
              extras.items.push({
                upc: part.upc,
                name: part.upc,
                quantity: part.quantity,
              });
            }
          }
        }
      }
    });
  }

  return extras;
}

function hardwareNiceName() {
  const boltHardware = boltHardwareUpcs();
  const lagHardware = lagHardwareUpcs();
  const concreteHardware = concreteHardwareUpcs();

  const names = {
    662187013046: {
      name: "Plastic Grommet Interm",
    },
    662187013039: {
      name: "Plastic Grommet Term",
    },
    // Covers
    662187012988: {
      name: "Plastic Covers",
    },
  };

  Object.entries(lagHardware).forEach(([key, upcs]) => {
    Object.entries(upcs).forEach(([type, upc]) => {
      names[upc] = {
        name: `Lag Screws ${key} ${type}`,
      };
    });
  });

  Object.entries(boltHardware).forEach(([key, upcs]) => {
    Object.entries(upcs).forEach(([type, upc]) => {
      names[upc] = {
        name: `Bolt ${key} ${type}`,
      };
    });
  });

  Object.entries(concreteHardware).forEach(([key, upcs]) => {
    const concreteMap = {
      tapcon: "Tapcon",
      "drop-in": "Drop In",
      "wedge-anchor": "Wedge Anchor",
    };

    const typeName = concreteMap[key];

    Object.entries(upcs).forEach(([type, upc]) => {
      names[upc] = {
        name: `${typeName} ${type}`,
      };
    });
  });

  return names;
}

function getPostsDetailsForRun(
  run: Run,
  runItemList: ItemListType,
  settings: ProjectSettings,
  state: Project
) {
  const map = hardwareNiceName();

  const items: { upc?: string; name: string; quantity: number }[] = [];

  const posts = getPosts(run, settings, state);

  const postsForRun = postsForTheRun(posts, run);

  if (typeof postsForRun.terminal !== "undefined") {
    items.push({
      name: "Terminal Drilled",
      quantity: postsForRun.terminal,
    });
  }

  if (typeof postsForRun.intermediate !== "undefined") {
    items.push({
      name: "Intermediate Drilled",
      quantity: postsForRun.intermediate,
    });
  }

  if (typeof postsForRun.stairPostTerminal !== "undefined") {
    items.push({
      name: "Terminal Undrilled",
      quantity: postsForRun.stairPostTerminal,
    });
  }

  if (typeof postsForRun.stairPostIntermediate !== "undefined") {
    items.push({
      name: "Intermediate Undrilled",
      quantity: postsForRun.stairPostIntermediate,
    });
  }

  if (typeof postsForRun.stairPostTransition !== "undefined") {
    const undrilledTerminal = items.find(
      (item) => item.name === "Terminal Undrilled"
    );

    if (undrilledTerminal) {
      undrilledTerminal.quantity =
        undrilledTerminal.quantity + postsForRun.stairPostTransition;
    } else {
      items.push({
        name: "Terminal Undrilled",
        quantity: postsForRun.stairPostTransition,
      });
    }
  }

  Object.values(runItemList).forEach((item) => {
    if ((map as any)?.[item.upc]) {
      items.push({
        upc: item.upc,
        name: (map as any)[item.upc].name,
        quantity: item.quantity,
      });
    }
  });

  return {
    items: items,
  };
}

function getTopRailDetailsForRun(
  run: Run,
  runItemList: ItemListType,
  settings: ProjectSettings,
  state: Project,
  distance: number,
  feet: number,
  inches: number
) {
  const items: {
    upc?: string;
    name: string;
    quantity: string | number;
    type?: string;
  }[] = [];

  settings = getRunSettings(run, settings);

  if (settings.toprailMaterial === "stainless-steel") {
    if (settings.stainlessSteelToprailType === "round") {
      items.push({
        name: "SS Round Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }

    if (settings.stainlessSteelToprailType === "flat") {
      items.push({
        name: "SS Flat Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }

    if (settings.stainlessSteelToprailType === "2507") {
      items.push({
        name: "SS 2507 Flat Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }
  }

  if (settings.toprailMaterial === "customer-provided") {
    items.push({
      name: "Customer Provided Top Rail",
      quantity: `${feet}' ${inches}"`,
      type: "toprail",
    });
  }

  if (settings.toprailMaterial === "aluminum") {
    if (settings.aluminumToprailType === "rectangular") {
      items.push({
        name: "Aluminum Rect Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }

    if (settings.aluminumToprailType === "shaped") {
      items.push({
        name: "Aluminum Shaped Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }

    if (settings.aluminumToprailType === "alum-p2p") {
      items.push({
        name: "Aluminum P2P Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }
  }

  if (settings.toprailMaterial === "wood") {
    items.push({
      name: "Wood Top Rail",
      quantity: `${feet}' ${inches}"`,
      type: "toprail",
    });

    if (settings.woodToprailSetup === "wood-alum-p2p") {
      items.push({
        name: "Aluminum P2P w/Wood Top Rail",
        quantity: `${feet}' ${inches}"`,
        type: "toprail",
      });
    }
  }

  const splices = {
    616453984899: {
      name: "Aluminum P2P Splice",
    },
    616453984936: {
      name: "Aluminum Top Rail Splice",
    },
    616320734466: {
      name: "SS Flat Top Rail Splice",
    },
    616320731564: {
      name: "SS Round Top Rail Splice",
    },
  };

  Object.values(runItemList).forEach((item) => {
    if ((splices as any)?.[item.upc]) {
      items.push({
        quantity: item.quantity,
        name: (splices as any)[item.upc].name,
        type: "splice",
        upc: item.upc,
      });
    }
  });

  const corners = calculateCorners(state.runs);

  corners.forEach((corner: Corner) => {
    if (corner.runs.toJS()[0] === run.id) {
      let cornerType = "adjustable";
      if (isRightAngleCorner(corner)) {
        cornerType = "right";
      } else {
        cornerType = "adjustable";
      }

      if (cornerType === "adjustable") {
        // Aluminum
        if (settings.toprailMaterial === "aluminum") {
          if (settings.aluminumToprailType !== "alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum Custom Corner",
            });
          }

          if (settings.aluminumToprailType === "alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum P2P Custom Corner",
            });
          }
        }

        // Stainless Steel
        if (settings.toprailMaterial === "stainless-steel") {
          if (settings.stainlessSteelToprailType === "round") {
            items.push({
              quantity: 1,
              name: "SSR Adjustable Elbow",
            });
          }

          if (settings.stainlessSteelToprailType === "flat") {
            items.push({
              quantity: 1,
              name: "SS Flat Adjustable Elbow",
            });
          }

          if (settings.stainlessSteelToprailType === "2507") {
            items.push({
              quantity: 1,
              name: "SS 2507 Flat Adjustable Elbow",
            });
          }
        }

        // Wood P2P
        if (settings.toprailMaterial === "wood") {
          if (settings.woodToprailSetup === "wood-alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum P2P Custom Corner",
            });
          }
        }
      } else if (cornerType === "right") {
        // Aluminum
        if (settings.toprailMaterial === "aluminum") {
          if (settings.aluminumToprailType !== "alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum 90 Degree Corner",
            });
          }

          if (settings.aluminumToprailType === "alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum P2P 90 Degree Corner",
            });
          }
        }
        // Stainless Steel
        if (settings.toprailMaterial === "stainless-steel") {
          if (settings.stainlessSteelToprailType === "round") {
            items.push({
              quantity: 1,
              name: "SS Round 90 Degree Elbow",
            });
          }

          if (settings.stainlessSteelToprailType === "flat") {
            items.push({
              quantity: 1,
              name: "SS Flat 90 Degree Elbow",
            });
          }

          if (settings.stainlessSteelToprailType === "2507") {
            items.push({
              quantity: 1,
              name: "SS 2507 Flat 90 Degree Elbow",
            });
          }
        }
        // Wood P2P
        if (settings.toprailMaterial === "wood") {
          if (settings.woodToprailSetup === "wood-alum-p2p") {
            items.push({
              quantity: 1,
              name: "Alum P2P 90 Degree Corner",
            });
          }
        }
      }
    }
  });

  const endcaps = calculateNumberOfEndCapsPerRun(run, corners);

  if (endcaps && endcaps > 0) {
    if (settings.toprailMaterial === "aluminum") {
      if (
        settings.aluminumToprailType !== "alum-p2p" &&
        settings.postMaterial !== "customer-provided"
      ) {
        items.push({
          quantity: endcaps,
          name: "Alum End Cap",
        });
      }
    }

    if (settings.toprailMaterial === "stainless-steel") {
      if (settings.stainlessSteelToprailType === "round") {
        items.push({
          quantity: endcaps,
          name: "SS Round End Cap",
        });
      }

      if (settings.stainlessSteelToprailType === "flat") {
        items.push({
          quantity: endcaps,
          name: "SS Flat End Cap",
        });
      }

      if (settings.stainlessSteelToprailType === "2507") {
        items.push({
          quantity: endcaps,
          name: "SS 2507 Flat End Cap",
        });
      }
    }
  }

  const topRailBracketsNames = getTopRailBracketsNames();

  Object.values(runItemList).forEach((item) => {
    if (topRailBracketsNames[item.upc]) {
      items.push({
        quantity: item.quantity,
        name: topRailBracketsNames[item.upc].name,
        type: "bracket",
        upc: item.upc,
      });
    }
  });

  return {
    items: items,
  };
}

function getTopRailBracketsNames() {
  const stainlessTopRailBrackets = getStainlessSteelTopRailBracketUpcs();

  const names: Record<string, { name: string }> = {};

  const postTypeName = {
    terminal: "Term",
    intermediate: "Int",
  };

  const bracketTypes = {
    fixed: "Fixed",
    adjustable: "Adjust",
  };

  Object.entries(stainlessTopRailBrackets).forEach(([toprailType, post]) => {
    Object.entries(post).forEach(([ssPostType, postTypes]) => {
      Object.entries(postTypes).forEach(([postType, brackets]) => {
        Object.entries(brackets).forEach(([bracketType, upc]) => {
          names[upc.toString()] = {
            name: `SS ${postTypeName[postType as keyof typeof postTypeName]} ${
              bracketTypes[bracketType as keyof typeof bracketTypes]
            } Saddle`,
          };
        });
      });
    });
  });

  return names;
}

function getServiceDetailsForRun(runItemList) {
  const names = {
    "SERVICE STAINLESS:SVC-HoleDrilling-Angled-S.S.": "SS Angled Hole Drilling",
    "SERVICE ALUMINUM:SVC-HoleDrilling-Angled-AL": "Alum Angled Hole Drilling",
  };

  const items = [];

  Object.values(runItemList).forEach((item) => {
    if (names[item.upc]) {
      items.push({
        quantity: item.quantity,
        name: names[item.upc],
        type: "service",
        upc: item.upc,
      });
    }
  });

  return {
    items: items,
  };
}

export function ItemList(
  state: Project,
  inventory: AllInventoryTypes[],
  project: Project
) {
  const runs = state.runs;
  const posts = state.posts;
  const settings = state.settings;
  const handrails = state.handrails;
  const gates = state.gates;

  const currentCanvas = state.currentCanvas;
  const firstCanvas = project.firstCanvas;
  const id = state.id;

  return {
    getItemListByRun() {
      const runs = state.canvases.reduce((map, canvas) => {
        canvas.runs.forEach((run) => {
          map = map.set(run.id, run);
        });

        return map;
      }, Map<string, Run>());

      // Important for calculating the number of fittings correctly.
      delete state.fittingsCornersSeen;

      const itemsInRun = runs.map((run: Run) => {
        const runSettings = getRunSettings(run, settings);
        const theSettings = mergeSettings(settings, runSettings);
        // Calculate Cable parts.
        let railDistance = 0;

        if (run.stairs && (run.stairs as any).continuousStairs) {
          railDistance = getTotalRailDistanceOfStairsRun(run, state);
        } else {
          railDistance = getRailDistance(run, state.stairs);
        }
        if (railDistance % 1 === 0 && railDistance % 2 !== 0) {
          // Make distance even for calculating inches.
          railDistance++;
        }
        let distanceInFeet = railDistance / pixelsPerFoot();
        const remainder = distanceInFeet % 1;

        let inches = Math.round(remainder * 12);

        if (inches === 12) {
          inches = 0;
          distanceInFeet++;
        }
        const feet = Math.floor(distanceInFeet);

        const distance = roundToNearestInch(distanceToFeet(railDistance));

        const numCables = getNumberOfCableRuns(settings);

        const runItemList = RunItemList(
          settings,
          run,
          inventory,
          state
        ).getItemList();

        return {
          index: runIndexToLetter(getRunIndex(run, state)),
          items: runItemList,
          cable: {
            feet: feet,
            inches: inches,
            runs: numCables,
            total: calculateRailingAmount(railDistance, theSettings),
          },
          fittings: {
            items: getFittingsForRun(runItemList),
          },
          posts: getPostsDetailsForRun(run, runItemList, theSettings, state),
          topRail: getTopRailDetailsForRun(
            run,
            runItemList,
            theSettings,
            state,
            distance,
            feet,
            inches
          ),
          extras: getExtrasForRun(runItemList, run),
          services: getServiceDetailsForRun(runItemList),
        };
      });

      return itemsInRun.reduce((newMap, item) => {
        newMap = newMap.set(item.index, item);

        return newMap;
      }, Map());
    },
    getItemList() {
      const postsPerRun = runs.map((run) => {
        const posts = getPosts(run, settings, state);

        return postsForTheRun(posts, run);
      });

      // Important for calculating the number of fittings correctly.
      delete state.fittingsCornersSeen;

      const customStainlessStockPostUpcs = getCustomStainlessStockPostUpcs();
      const customAluminumStockPostUpcs = getCustomAluminumStockPostUpcs();
      const customWoodStockPostUpcs = getWoodStockPostUpcs();

      // Generate item list with each runs item list being added.
      const startValue: ItemListType = {};

      let itemList = runs.reduce((list, run) => {
        const runItemList = RunItemList(
          settings,
          run,
          inventory,
          state
        ).getItemList();

        Object.entries(runItemList).forEach(([key, value]) => {
          if (list[key] === undefined) {
            list[key] = value;

            if ((customStainlessStockPostUpcs as any)?.[key]) {
              const item = stainlessSteelCustomPostDescriptions(
                parseInt(key, 10).toString(),
                list[key]
              );

              list[key].description = item.description;
            }

            if ((customAluminumStockPostUpcs as any)?.[key]) {
              const item = aluminumCustomPostDescriptions(
                parseInt(key, 10).toString(),
                list[key]
              );

              list[key].description = item.description;
            }

            if ((customWoodStockPostUpcs as any)?.[key]) {
              const item = woodCustomPostDescriptions(
                parseInt(key, 10).toString(),
                list[key]
              );

              list[key].description = item.description;
            }
          } else {
            if (customStainlessStockPostUpcs[key]) {
              // Merge descriptions.
              if (list[key].counts && value.counts) {
                Object.entries(value.counts).forEach(([inches, count]) => {
                  if (list[key].counts[inches]) {
                    list[key].counts[inches] += count;
                  } else {
                    list[key].counts[inches] = count;
                  }
                });

                const item = stainlessSteelCustomPostDescriptions(
                  parseInt(key, 10),
                  list[key]
                );

                list[key].description = item.description;
              }
            }

            if (customAluminumStockPostUpcs[key]) {
              // Merge descriptions.
              if (list[key].counts && value.counts) {
                Object.entries(value.counts).forEach(([inches, count]) => {
                  if (list[key].counts[inches]) {
                    list[key].counts[inches] += count;
                  } else {
                    list[key].counts[inches] = count;
                  }
                });

                const item = aluminumCustomPostDescriptions(
                  parseInt(key, 10),
                  list[key]
                );

                list[key].description = item.description;
              }
            }

            if (customWoodStockPostUpcs[key]) {
              // Merge descriptions.
              if (list[key].counts && value.counts) {
                Object.entries(value.counts).forEach(([inches, count]) => {
                  if (list[key].counts[inches]) {
                    list[key].counts[inches] += count;
                  } else {
                    list[key].counts[inches] = count;
                  }
                });

                const item = woodCustomPostDescriptions(
                  parseInt(key, 10),
                  list[key]
                );

                list[key].description = item.description;
              }
            }

            list[key].quantity = roundToHundreth(
              list[key].quantity + value.quantity
            );

            list[key].total = roundToHundreth(
              list[key].total + value.quantity * value.price
            );
          }
        });

        return list;
      }, startValue);

      // Add P2P install kit.
      if (itemList[getWoodP2PWasherUpc().toString()]) {
        itemList = addItem(
          itemList,
          inventory,
          getP2PInstallKitUpc().toString(),
          // Every 100 ft add an install kit. Washers are 2 per foot.
          Math.ceil(itemList[getWoodP2PWasherUpc()].quantity / 2 / 100)
        );
      }

      // Combine all black oxide bundles from runs.
      itemList = combineBlackOxideBundles(itemList, inventory);

      if (handrails.size) {
        itemList = addHandrails(itemList, inventory, state);
      }

      // Add posts item list to project.
      if (posts.size > 0) {
        posts.forEach((post) => {
          const postItemList = PostItemList(
            settings,
            post,
            inventory
          ).getItemList();

          Object.entries(postItemList).forEach(([key, value]) => {
            if (itemList[key] === undefined) {
              itemList[key] = value;
            } else {
              itemList[key].quantity = roundToHundreth(
                itemList[key].quantity + value.quantity
              );
              itemList[key].total = roundToHundreth(
                (itemList[key].total || 0) + value.quantity * value.price
              );
            }
          });
        });
      }

      const totalPosts = getTotalPosts(postsPerRun);

      const toprailLengths = calculateTopRailLengths(state);

      const toprailLengthCounts = toprailLengths
        ? getAllLengthCounts(toprailLengths)
        : undefined;

      if (runs.size > 0 && toprailLengthCounts) {
        // Handle toprail calculation.
        itemList = addCalculatedToprail(
          itemList,
          inventory,
          state,
          toprailLengthCounts,
          toprailLengths,
        );
      }

      if (firstCanvas === id) {
        // Stainless Install Kits scale drill bits.
        if (settings.toprailMaterial === "stainless-steel") {
          const screws = getQuantityScrewsPerRun(totalPosts);
          const drillBitUpcs = getStainlessToprailInstallKitDrillBitUpcs();
          const drillBitUpc =
            drillBitUpcs[
              settings.stainlessSteelToprailType as keyof typeof drillBitUpcs
            ];

          // If we have 40 posts we want to calculate at least one kit present so we can trigger overrages.
          const kits = Math.floor(
            // Low profile only uses 2 screws per post.
            screws / (settings.stainlessPostCapType === "lo-profile" ? 40 : 80)
          );

          if (kits > 0 && settings.overrages) {
            // Now being added by overages. Use 0.5 as an indicator that this part was auto added. Force quantity instead of kits quantity.
            itemList = addItemForceQuantity(
              itemList,
              inventory,
              drillBitUpc,
              0.5
            );
          }

          // Add 2507 install kit.
          if (settings.stainlessSteelToprailType === "2507") {
            const installKitUpc = get2507InstallKitUpc();

            itemList = addItemForceQuantity(
              itemList,
              inventory,
              installKitUpc,
              1
            );
          }
        }

        // Add electro polish service.
        if (
          settings.postMaterial === "stainless-steel" &&
          settings.stainlessPostShape === "round" &&
          settings.includeElectroPolish === "yes"
        ) {
          const electroPolishUpc = getElectroPolishUpc();

          itemList = addItemForceQuantity(
            itemList,
            inventory,
            electroPolishUpc,
            1
          );
        }

        // Mounting bit for systems with baseplates.
        if (runs.size > 0 && includesBasePlate(settings)) {
          // Aluminum systems only need mounting bit.
          if (settings.postMaterial === "aluminum") {
            itemList = addMountingBit(itemList, inventory);
          }
        }

        // Add wood stain and sealer for systems with wood toprail components.
        if (runs.size > 0 && hasWoodToprail(settings) && toprailLengths) {
          itemList = addWoodStain(
            itemList,
            inventory,
            settings,
            toprailLengths,
            runs,
            totalPosts
          );
          itemList = addWoodSealer(itemList, inventory);
        }

        // Add hardware piece for concrete hardware option for drop in anchors.
        if ((runs.size > 0 || posts.size > 0) && settings.hardwareType === "concrete" && settings.concreteHardwareType === 'drop-in') {
          const { concreteHardwareSize }  = settings;
          const concreteToolUpcs = concreteHardwareToolUpcs();
          const toolUpc = concreteToolUpcs[settings.concreteHardwareType][concreteHardwareSize];

          if ( toolUpc ) {
            addItemForceQuantity(itemList, inventory, toolUpc, 1);
          }
        }
      }

      // Correct surface mount quantity at a project level.
      const surfaceMountInstallKits = traverseSchema(
        getSurfaceMountInstallKitUpcs(),
        {}
      );

      Object.entries(surfaceMountInstallKits).forEach(([upc, _value]) => {
        if (firstCanvas === id) {
          if (itemList[upc]) {
            // Set to only one.
            itemList[upc].quantity = 1;
            itemList[upc].total = itemList[upc].price;
          }
        } else {
          delete itemList[upc];
        }
      });

      // Add subtotal automatically.
      if (firstCanvas === id) {
        if (Object.values(itemList).length) {
          itemList = addItemForceQuantity(itemList, inventory, "SubTotal", 1);
        }
      }

      // Add touchup paint for aluminum orders.
      if (
        (settings.postMaterial === "aluminum" ||
          settings.toprailMaterial === "aluminum") &&
        settings.postMaterial !== "customer-provided"
      ) {
        if (firstCanvas === id && runs.size > 0) {
          if (project) {
            itemList = calculateTouchupPaint(
              itemList,
              inventory,
              project.canvases,
              project.settings,
              project
            );
          }
        }
      }

      // Add cable cutter, crimper, die and other tools.
      if (firstCanvas === id && runs.size > 0) {
        if (settings.cableCutter.include) {
          const cutterUpcs = getCableCutterUpcs();

          let cutterSize = "small";

          if (settings.cableCutter.size === "1/8") {
            cutterSize = "small";
          }

          if (settings.cableCutter.size === "3/16") {
            cutterSize = "large";
          }

          if (settings.cableCutter.size === "auto") {
            if (
              settings.cableSize === "1 x 19 - 3/16”" ||
              settings.cableSize === "7 x 7 - 3/16”"
            ) {
              cutterSize = "large";
            }

            if (settings.cableSize === "1 x 19 - 1/8”") {
              cutterSize = "small";
            }
          }

          const cutterUpc = cutterUpcs[cutterSize as keyof typeof cutterUpcs];

          itemList = addItem(itemList, inventory, cutterUpc, 1);
        }

        if (settings.cableCrimper.include) {
          const crimperUpc = getCrimperUpc();

          itemList = addItem(itemList, inventory, crimperUpc.toString(), 1);

          const swageDieCrimperUpcs = getDieCrimperUpcs();

          // Swage Die Crimper for 1/8" cable.
          const swageDieCrimper18Upc = swageDieCrimperUpcs["1/8"];

          // Swage Die Crimper for 3/16" cable.
          const swageDieCrimper316Upc = swageDieCrimperUpcs["3/16"];

          let swageDieForCrimperUpc = 0;

          if (settings.cableCrimper.die === "auto") {
            const dieForCrimperUpcs = {
              "1 x 19 - 1/8”": swageDieCrimper18Upc,
              "1 x 19 - 3/16”": swageDieCrimper316Upc,
              "7 x 7 - 3/16”": swageDieCrimper316Upc,
            };

            swageDieForCrimperUpc =
              dieForCrimperUpcs[
                settings.cableSize as keyof typeof dieForCrimperUpcs
              ];
          }

          if (settings.cableCrimper.die === "3/16") {
            swageDieForCrimperUpc = swageDieCrimper316Upc;
          }

          if (settings.cableCrimper.die === "1/8") {
            swageDieForCrimperUpc = swageDieCrimper18Upc;
          }

          itemList = addItem(
            itemList,
            inventory,
            swageDieForCrimperUpc.toString(),
            1
          );

          // Cable Crimper Holder.
          const cableCrimperHolderUpc = getCableCrimperHolderUpc();

          itemList = addItem(
            itemList,
            inventory,
            cableCrimperHolderUpc.toString(),
            1
          );
        }
      }

      // Add passivation.
      if (firstCanvas === id && runs.size) {
        if (isMarine(settings) || settings.includePassivation === "yes") {
          const passivationUpc = getPassivationServiceName();

          if (passivationUpc) {
            itemList = addItem(itemList, inventory, passivationUpc, 1);
          }
        }

        const sprayUpc = getPassivationSprayUpc();

        if (sprayUpc) {
          itemList = addItem(itemList, inventory, sprayUpc.toString(), 1);
        }

        if (isMarine(settings)) {
          const rustRescueUpc = getRustRescueUpc();

          if (rustRescueUpc) {
            itemList = addItem(itemList, inventory, rustRescueUpc, 1);
          }
        }
      }

      if (firstCanvas === id && runs.size) {
        // Only aluminum systems need galvanic screw protection.
        if (
          includesBasePlate(settings) &&
          settings.postMaterial === "aluminum"
        ) {
          itemList = addGalvanicScrewProtection(itemList, inventory);
        }
      }

      if (settings.postMaterial === "aluminum") {
        // Grommets only use if 1/8th inch cable is selected.
        if (settings.cableSize === "1 x 19 - 1/8”") {
          if (firstCanvas === id && runs.size > 0) {
            const grommetUpcs = getGrommetUpcs();

            // Install tool only one per project.
            if (firstCanvas === id) {
              addItemForceQuantity(
                itemList,
                inventory,
                grommetUpcs.installKit,
                1
              );
            }
          }
        }
      }

      if (gates.size) {
        const gateUpcs = getGateUpcs();

        const cableSize =
          settings.cableSize === "1 x 19 - 1/8”" ? "1/8" : "3/16";
        const postMaterial = settings.postMaterial;

        let gateUpc = 0;

        if (postMaterial === "aluminum") {
          gateUpc = gateUpcs[postMaterial][cableSize];
        } else if (postMaterial === "stainless-steel") {
          const stainlessPostShape = settings.stainlessPostShape;

          gateUpc = gateUpcs[postMaterial][stainlessPostShape][cableSize];
        } else if (
          postMaterial === "wood" ||
          postMaterial === "customer-provided"
        ) {
          // Wood or customer provided posts will match wood post type which is currently only one option.
          gateUpc = gateUpcs["wood"]["balau"][cableSize];
        }

        if (gateUpc) {
          itemList = addItem(itemList, inventory, gateUpc, gates.size);
        }
      }

      const corners = calculateCorners(runs);

      if (corners.length) {
        let cornerType = "adjustable";
        const cornerUpcs = getCornerUpcs();
        const {
          postMaterial,
          toprailMaterial,
          aluminumToprailType,
          aluminumColor,
          stainlessSteelToprailType,
        } = settings;

        corners.forEach((corner) => {
          const isSingleCorner =
            state.corners.find((val) => {
              return (
                val.type === "single" &&
                arrayEquals(
                  Object.keys(val.id.toJS()).sort(),
                  corner.runs.toJS().sort()
                )
              );
            }) !== undefined;

          if (isSingleCorner) return;

          if (isRightAngleCorner(corner)) {
            cornerType = "right";
          } else {
            cornerType = "adjustable";
          }

          if (
            toprailMaterial === "aluminum" &&
            postMaterial !== "customer-provided"
          ) {
            const cornerUpc =
              cornerUpcs[toprailMaterial][aluminumToprailType][aluminumColor][
                cornerType
              ];

            if (cornerUpc) {
              itemList = addItem(itemList, inventory, cornerUpc, 1);

              if (cornerType === "right") {
                const cornerInstallKitUpcs = getToprailCornerInstallKitUpcs();
                itemList = addItemForceQuantity(
                  itemList,
                  inventory,
                  cornerInstallKitUpcs[toprailMaterial],
                  1
                );
              }
            }
          }

          if (toprailMaterial === "stainless-steel") {
            const cornerUpc =
              cornerUpcs[toprailMaterial][stainlessSteelToprailType][
                cornerType
              ];

            if (cornerUpc) {
              itemList = addItem(itemList, inventory, cornerUpc, 1);
            }
          }

          // Wood Alum P2P.
          if (
            toprailMaterial === "wood" &&
            settings.woodToprailSetup === "wood-alum-p2p"
          ) {
            const cornerUpc =
              cornerUpcs["aluminum"]["alum-p2p"][aluminumColor][cornerType];

            if (cornerUpc) {
              itemList = addItem(itemList, inventory, cornerUpc, 1);

              if (cornerType === "right") {
                const cornerInstallKitUpcs = getToprailCornerInstallKitUpcs();
                itemList = addItemForceQuantity(
                  itemList,
                  inventory,
                  cornerInstallKitUpcs[toprailMaterial],
                  1
                );
              }
            }
          }
        });
      }

      // Add fascia bracket mounts for aluminum corners.
      if ( settings.postMaterial === "aluminum" && settings.mountStyle === "fascia" && ( settings.fasciaBracketType === "alum-4hole-bracket-shims" || settings.fasciaBracketType === "alum-4hole-bracket" ) ) {
        itemList = addAluminumFasciaBrackets( itemList, inventory, corners, state );

        if ( firstCanvas === id ) {
          const fasciaBracketInstallKitUpc = getFasciaBracketInstallKitUpc();
          itemList = addItemForceQuantity( itemList, inventory, fasciaBracketInstallKitUpc, 1 );
        }
      }

      // End caps.
      const endcaps = calculateNumberOfEndCaps(runs, corners, settings);

      if (endcaps) {
        const endCapUpcs = getEndCapUpcs();
        const { aluminumColor, postMaterial } = settings;

        if (endcaps.aluminum && postMaterial !== "customer-provided") {
          Object.entries(endcaps.aluminum).forEach(([key, value]) => {
            if (value && value > 0) {
              const endCapUpc = endCapUpcs.aluminum[key][aluminumColor];

              if (endCapUpc) {
                itemList = addItem(itemList, inventory, endCapUpc, value);
              }
            }
          });
        }

        if (endcaps["stainless-steel"]) {
          Object.entries(endcaps["stainless-steel"]).forEach(([key, value]) => {
            if (value && value > 0) {
              const endCapUpc = endCapUpcs["stainless-steel"][key];

              if (endCapUpc) {
                itemList = addItem(itemList, inventory, endCapUpc, value);
              }
            }
          });
        }
      }

      // Epoxy.
      if (runs.size) {
        let allPosts = 0;
        let saddleBracketPosts = 0;

        runs.forEach((run) => {
          const runSettings = getRunSettings(run, settings);
          const postsForRun = postsForTheRun(
            getPosts(run, runSettings, state),
            run
          );

          if (hasP2PSaddleBrackets(runSettings)) {
            saddleBracketPosts += postsForRun.stairPostIntermediate || 0;
          }

          if (hasStainlessSteelPostsForEpoxy(runSettings)) {
            allPosts +=
              (postsForRun.intermediate || 0) +
              (postsForRun.terminal || 0) +
              (postsForRun.stairPostIntermediate || 0) +
              (postsForRun.stairPostTerminal || 0) +
              (postsForRun.stairPostTransition || 0);
          }
        });

        const systemSize = "large";
        let epoxyUpcs = getEpoxyUpcs();

        let epoxyKitUpcs = getEpoxyKitUpcs();

        if (
          settings.postMaterial === "aluminum" &&
          settings.toprailMaterial === "aluminum"
        ) {
          epoxyKitUpcs = getAluminumEpoxyKitUpcs();
          epoxyUpcs = getAluminumEpoxyUpcs();
        }

        const epoxyUpc = epoxyUpcs[systemSize];

        if (allPosts > 0 || saddleBracketPosts > 0) {
          if (systemSize === "large") {
            // Every 8 posts add an epoxy.
            let numberOfEpoxy =
              allPosts + saddleBracketPosts > 8
                ? Math.ceil((allPosts + saddleBracketPosts) / 8) - 1
                : 0;

            if (numberOfEpoxy > 0) {
              itemList = addItem(itemList, inventory, epoxyUpc, numberOfEpoxy);
            }

            // Only add 1 kit for project.
            if (firstCanvas === id) {
              let kitQuantity = 1;

              if (
                settings.postMaterial === "aluminum" &&
                settings.toprailMaterial === "aluminum"
              ) {
                kitQuantity += numberOfEpoxy;
              }

              itemList = addItemForceQuantity(
                itemList,
                inventory,
                epoxyKitUpcs["readhead-g5"],
                kitQuantity
              );
            }
          }

          if (systemSize === "small") {
            // No need to calculate saddle brackets for small system size as it will scale to large if 8 saddle bracket posts are found.
            let numberOfEpoxy = allPosts > 2 ? Math.ceil(allPosts / 2) - 1 : 0;

            if (numberOfEpoxy > 0) {
              itemList = addItem(itemList, inventory, epoxyUpc, numberOfEpoxy);
            }

            itemList = addItemForceQuantity(
              itemList,
              inventory,
              epoxyKitUpcs["3m-scotch-weld"],
              1
            );
          }
        }
      }

      // Round up cable lengths.
      if (runs.size) {
        const cables = cableUpcs();
        const cables2507 = cableUpcs2507();
        const upcs = [...Object.values(cables), ...Object.values(cables2507)];
        upcs.forEach((cableUpc) => {
          if (itemList[cableUpc]) {
            itemList[cableUpc].quantity = Math.ceil(
              itemList[cableUpc].quantity
            );
            itemList[cableUpc].total =
              itemList[cableUpc].quantity * itemList[cableUpc].price;
          }
        });
      }

      itemList = changeUnitOfMeasure( sortItemList(itemList) );
      return itemList;
    },
  };
}

function hasP2PSaddleBrackets(settings: ProjectSettings) {
  return (
    (settings.postMaterial === "aluminum" &&
      settings.toprailMaterial === "aluminum") ||
    (settings.postMaterial === "aluminum" &&
      settings.toprailMaterial === "wood" &&
      settings.woodToprailSetup === "wood-alum-p2p" &&
      settings.woodAlumP2PStairsSetup === "on-top-brackets")
  );
}

function hasStainlessSteelPostsForEpoxy(settings: ProjectSettings) {
  return (
    (settings.toprailMaterial === "stainless-steel" &&
      settings.postMaterial === "stainless-steel") ||
    (settings.toprailMaterial === "wood" &&
      settings.postMaterial === "stainless-steel") ||
    (settings.postMaterial === "stainless-steel" &&
      settings.toprailMaterial === "customer-provided")
  );
}

function addMountingBit(
  itemList: ItemListType,
  inventory: AllInventoryTypes[]
) {
  itemList = addItemForceQuantity(
    itemList,
    inventory,
    getBasePlateBitForScrewsUpc(),
    1
  );

  return itemList;
}

function includesBasePlate(settings: ProjectSettings) {
  return (
    settings.mountStyle === "deck" ||
    settings.mountStyle === "core" ||
    settings.mountStyle === "pony-wall"
  );
}

function addBlackOxideKitCables(
  itemList: Record<number, Item>,
  inventory: AllInventoryTypes[],
  railDistance: number,
  railHeight: "36" | "42" | "custom",
  kits: { 10: number; 20: number; 30: number; 40: number },
  totalFootage: number,
  settings: ProjectSettings
): Record<number, Item> {
  const numCables = getNumberOfCableRuns(settings);
  const numberOfCables = numCables;

  const sizes = Object.keys(kits).map((upc) =>
    parseInt(upc, 10)
  ) as (keyof typeof kits)[];

  let result: number[] = [];
  let shouldBreak = false;

  for (let i = 0; i < sizes.length; i++) {
    let size = sizes[i];
    let numChunks = 0;

    if (shouldBreak) {
      break;
    }

    if (totalFootage < size) {
      numChunks = 1;
    }

    for (let j = 0; j < numChunks; j++) {
      result.push(size);
      shouldBreak = true;
    }
  }

  result.forEach((size) => {
    itemList = addItem(
      itemList,
      inventory,
      (kits as any)?.[size],
      numberOfCables
    );
  });

  return itemList;
}

function combineBlackOxideBundles(
  itemList: ItemListType,
  inventory: AllInventoryTypes[]
) {
  const bundles = getBlackOxideCableBundleUpcs();

  const upcToSize = Object.entries(bundles).reduce((acc, [size, upc]) => {
    acc[upc] = size;

    return acc;
  }, {});

  // Bin pack all bundles below 100ft into 100ft lengths.
  const maxLength = 100;
  const bins = [];

  const lengths = [10, 20, 30, 40];

  const quantities = [0, 0, 0, 0];

  Object.values(bundles).forEach((upc) => {
    if (itemList[upc]) {
      const item = itemList[upc];

      let quantity = item.quantity;

      const size = upcToSize[upc];

      if (size === "10") {
        quantities[0] += quantity;
        delete itemList[upc];
      }

      if (size === "20") {
        quantities[1] += quantity;
        delete itemList[upc];
      }

      if (size === "30") {
        quantities[2] += quantity;
        delete itemList[upc];
      }

      if (size === "40") {
        quantities[3] += quantity;
        delete itemList[upc];
      }
    }
  });

  for (let i = 0; i < lengths.length; i++) {
    for (let j = 0; j < quantities[i]; j++) {
      let placed = false;

      for (let k = 0; k < bins.length; k++) {
        if (bins[k] + lengths[i] <= maxLength) {
          bins[k] += lengths[i];
          placed = true;
          break;
        }
      }

      if (!placed) {
        bins.push(lengths[i]);
      }
    }
  }

  const numberOf100ftBundles = bins.length;

  addItem(itemList, inventory, bundles["100"], numberOf100ftBundles);

  // Condense bundles.
  Object.values(bundles).forEach((upc) => {
    if (itemList[upc]) {
      const item = itemList[upc];

      let quantity = item.quantity;

      const size = upcToSize[upc];

      if (size === "10") {
        // Bundle up to 1000ft.
        if (quantity >= 100) {
          const numberOfBundles = Math.floor(quantity / 100);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["1000"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 100;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 500ft.
        if (quantity >= 50) {
          const numberOfBundles = Math.floor(quantity / 50);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["500"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 50;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 250ft.
        if (quantity >= 25) {
          const numberOfBundles = Math.floor(quantity / 25);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["250"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 25;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 100ft.
        if (quantity >= 10) {
          const numberOfBundles = Math.floor(quantity / 10);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["100"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 10;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 40ft.
        if (quantity >= 4) {
          const numberOfBundles = Math.floor(quantity / 4);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["40"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 4;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 20ft.
        if (quantity >= 2) {
          const numberOfBundles = Math.floor(quantity / 2);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["20"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 2;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        if (quantity === 0) {
          delete itemList[upc];
        }
      }

      if (size === "20") {
        // Bundle up to 1000ft.
        if (quantity >= 50) {
          const numberOfBundles = Math.floor(quantity / 50);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["1000"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 50;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 500ft.
        if (quantity >= 25) {
          const numberOfBundles = Math.floor(quantity / 25);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["500"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 25;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 100ft.
        if (quantity >= 5) {
          const numberOfBundles = Math.floor(quantity / 5);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["100"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 5;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 40ft.
        if (quantity >= 2) {
          const numberOfBundles = Math.floor(quantity / 2);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["40"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 2;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        if (quantity === 0) {
          delete itemList[upc];
        }
      }

      if (size === "100") {
        // Bundle up to 1000ft.
        if (quantity >= 10) {
          const numberOfBundles = Math.floor(quantity / 10);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["1000"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 10;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 500ft.
        if (quantity >= 5) {
          const numberOfBundles = Math.floor(quantity / 5);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["500"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 5;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        if (quantity === 0) {
          delete itemList[upc];
        }
      }

      if (size === "250") {
        // Bundle up to 1000ft.
        if (quantity >= 4) {
          const numberOfBundles = Math.floor(quantity / 4);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["1000"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 4;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        // Bundle up to 500ft.
        if (quantity >= 2) {
          const numberOfBundles = Math.floor(quantity / 2);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["500"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 2;
            quantity = itemList[upc].quantity;

            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }
      }

      if (size === "500") {
        // Bundle up to 1000ft.
        if (quantity >= 2) {
          const numberOfBundles = Math.floor(quantity / 2);

          if (numberOfBundles > 0) {
            itemList = addItem(
              itemList,
              inventory,
              bundles["1000"],
              numberOfBundles
            );
            itemList[upc].quantity -= numberOfBundles * 2;
            quantity = itemList[upc].quantity;
            itemList[upc].total = roundToHundreth(
              quantity * itemList[upc].price
            );

            if (itemList[upc].quantity === 0) {
              delete itemList[upc];
            }
          }
        }

        if (quantity === 0) {
          delete itemList[upc];
        }
      }
    }
  });

  return itemList;
}

function addBlackOxideBundlesCables(
  itemList: Record<number, Item>,
  inventory: AllInventoryTypes[],
  railDistance: number,
  railHeight: string,
  bundles: {
    10: number;
    20: number;
    30: number;
    40: number;
    100: number;
    250: number;
    500: number;
    1000: number;
  },
  totalFootage: number,
  settings: ProjectSettings
) {
  const numCables = getNumberOfCableRuns(settings);
  const numberOfCables = numCables;
  const distance = roundToNearestInch(distanceToFeet(railDistance));

  const sizes = Object.keys(bundles).map((upc) => parseInt(upc, 10));

  const cablesPerSize: Record<number, number> = {};

  // Calculate how many cables can fit in each bundle size.
  sizes.forEach((size) => {
    cablesPerSize[size] = Math.floor(size / distance);
  });

  const cableRelations: Record<
    number,
    {
      previous: { size: string; cables: number } | null;
      next: { size: string; cables: number } | null;
    }
  > = {};

  // Set up an object that will keep track of the count of sizes.
  const start: Record<number, number> = {};
  const countOfBundles = sizes.reduce((acc, size) => {
    acc[size] = 0;

    return acc;
  }, start);

  let minimumSize = 0;
  let sumOfCables = 0;
  let maximumCables = numberOfCables + 1;

  cablesPerSize[minimumSize] = 0;

  Object.entries(cablesPerSize).forEach(([size, cables], index, allSizes) => {
    if (cables > cablesPerSize[minimumSize] && cables <= numberOfCables) {
      minimumSize = parseInt(size);

      cableRelations[parseInt(size)] = {
        previous: allSizes[index - 1]
          ? { size: allSizes[index - 1][0], cables: allSizes[index - 1][1] }
          : null,
        next: allSizes[index + 1]
          ? { size: allSizes[index + 1][0], cables: allSizes[index + 1][1] }
          : null,
      };
    }
  });

  if (cablesPerSize[minimumSize]) {
    sumOfCables += cablesPerSize[minimumSize];
    countOfBundles[minimumSize] += 1;
  }

  // Now start at the maximum size and work backwards to achieve a sum that matches the number of cables optimally.
  while (sumOfCables < numberOfCables && minimumSize > 0) {
    if (cablesPerSize[minimumSize] + sumOfCables <= numberOfCables) {
      sumOfCables += cablesPerSize[minimumSize];
      countOfBundles[minimumSize] += 1;
    } else if (cablesPerSize[minimumSize] + sumOfCables <= maximumCables) {
      sumOfCables += cablesPerSize[minimumSize];
      countOfBundles[minimumSize] += 1;
    } else {
      minimumSize = parseInt(cableRelations[minimumSize].previous?.size || "0");
    }
  }

  sizes.forEach((size) => {
    const upc = (bundles as any)?.[size];
    if (typeof upc === "number") {
      itemList = addItem(itemList, inventory, upc, countOfBundles[size]);
    }
  });

  return itemList;
}

export function addBlackOxideCable(
  itemList: Record<number, Item>,
  inventory: AllInventoryTypes[],
  railDistance: number,
  railHeight: "36" | "42" | "custom",
  run: Run,
  settings: ProjectSettings,
  state: Project
): Record<number, Item> {
  const totalFootage = distanceToFeet(railDistance);

  let kits: { 10: number; 20: number; 30: number; 40: number } | undefined;

  if (
    settings.blackOxideBundles === "use-kits" &&
    totalFootage <= 40 &&
    settings.cableFittingType !== "surface-mount"
  ) {
    const kitUpcs = getBlackOxideCableKitUpcs();

    if (settings.cableFittingType === "swageless") {
      let swagelessType = settings.swagelessType
        ? settings.swagelessType
        : "type-2";

      const receiverStudSize = settings.receiverStudSize;

      kits = (kitUpcs as any)[settings.cableFittingType]?.[swagelessType]?.[
        receiverStudSize
      ];
    }

    if (settings.cableFittingType === "thru-post") {
      kits = kitUpcs["field-swaged"];
    }

    if (settings.cableFittingType === "thru-post") {
      if (settings.endFitting === "quick-nut") {
        // @ts-ignore
        kits = kitUpcs["quick-receiver"]?.[settings.receiverStudSize];
      }
    }
    if (kits) {
      return addBlackOxideKitCables(
        itemList,
        inventory,
        railDistance,
        railHeight,
        kits,
        totalFootage,
        settings
      );
    }
  }

  // If an individual cable run exceeds 40 feet, use bundles.
  if (
    settings.blackOxideBundles === "use-bundles" ||
    totalFootage > 40 ||
    settings.cableFittingType === "surface-mount"
  ) {
    const bundleUpcs = getBlackOxideCableBundleUpcs();

    const bundles = bundleUpcs;

    return addBlackOxideBundlesCables(
      itemList,
      inventory,
      railDistance,
      railHeight,
      bundles,
      totalFootage,
      settings
    );
  }

  return itemList;
}

export function getCableDistanceContinuousStairs(run: Run, state: Project) {
  const posts = getPosts(run, state.settings, state);

  const groups = getGroupedPostsForRun(posts, run);

  let total = 0;
  Object.entries(groups).forEach(([key, value]: [string, any]) => {
    if (value.length < 2) {
      return;
    }

    const { distance } = getDistancesFromPostGroupsForRun(
      run,
      state.stairs,
      value,
      key
    );

    total += distance;
  });

  return total;
}

export function getAllLengthCounts(allLengths: TopRailLengths) {
  allLengths = JSON.parse(JSON.stringify(allLengths));
  const toprailDivisor = toprailLength();

  if (allLengths["stainless-steel"]) {
    if (allLengths["stainless-steel"].flat) {
      allLengths["stainless-steel"].flat = calculateSticksOfToprail(
        allLengths["stainless-steel"].flat,
        toprailDivisor
      );
    }

    if (allLengths["stainless-steel"].round) {
      allLengths["stainless-steel"].round = calculateSticksOfToprail(
        allLengths["stainless-steel"].round,
        toprailDivisor
      );
    }

    if (allLengths["stainless-steel"]["2507"]) {
      allLengths["stainless-steel"]["2507"] = calculateSticksOfToprail(
        allLengths["stainless-steel"]["2507"],
        toprailDivisor
      );
    }
  }

  if (allLengths.aluminum) {
    if (allLengths.aluminum.rectangular) {
      allLengths.aluminum.rectangular = calculateSticksOfToprail(
        allLengths.aluminum.rectangular,
        toprailDivisor
      );
    }

    if (allLengths.aluminum.shaped) {
      allLengths.aluminum.shaped = calculateSticksOfToprail(
        allLengths.aluminum.shaped,
        toprailDivisor
      );
    }
  }

  if (allLengths.wood) {
    Object.entries(allLengths.wood).forEach(([key, value]) => {
      if (value && typeof value === "object" && key.startsWith("wood")) {
        allLengths.wood![key] = calculateWoodSticksOfToprail(
          value,
          toprailDivisor
        );
      }
    });
  }

  return allLengths;
}

function findMatchingP2PSize(length, sizes) {
  let sizeThatWorks = 0;
  let lastSeenSize;

  if (length === 21) {
    return 20;
  }

  Object.keys(sizes)
    .map((size) => parseInt(size, 10))
    .sort((a, b) => b - a)
    .forEach((size) => {
      lastSeenSize = size;

      if (length <= size) {
        sizeThatWorks = size;
      }
    });

  if (!sizeThatWorks > 0) {
    sizeThatWorks = lastSeenSize;
  }

  return sizeThatWorks;
}

function calculateWoodSticksOfToprail(
  lengths: number[],
  toprailDivisor: number
) {
  lengths = lengths.flatMap((length) => {
    // Add 1ft to wood lengths when rounded.
    length = Math.ceil(length) + 1;

    const divisor = Math.ceil(length / toprailDivisor);

    const theLengths = [];

    // Calculate the split of the length for instance if we have a 21 ft run this will end up being 10.5 for the two run lengths in the middle.
    const divisorValue = length / divisor;
    for (let i = 0; i < divisor; i++) {
      theLengths.push(divisorValue);
    }

    if (lengths.length === 0) {
      theLengths.push(length);
    }

    return theLengths;
  });

  return lengths;
}

function calculateSticksOfToprail(lengths: number[], toprailDivisor: number) {
  lengths = lengths.flatMap((length) => {
    if (length > toprailDivisor) {
      const divisor = Math.ceil(length / toprailDivisor);

      const theLengths = [];

      // Calculate the split of the length for instance if we have a 21 ft run this will end up being 10.5 for the two run lengths in the middle.
      const divisorValue = length / divisor;

      for (let i = 0; i < divisor; i++) {
        theLengths.push(divisorValue);
      }

      return theLengths;
    }

    return length;
  });
  const groupsCount = bestFit(lengths, toprailDivisor);

  return groupsCount;
}

function bestFit(cableLengths: number[], topLength: number = 20) {
  const bins = [];

  cableLengths.sort((a, b) => b - a);

  for (let i = 0; i < cableLengths.length; i++) {
    const cableLength = cableLengths[i];
    let waste = Infinity;

    let binIndex = -1;

    for (let j = 0; j < bins.length; j++) {
      if (bins[j] + cableLength <= topLength) {
        const currentWaste = topLength - (bins[j] + cableLength);
        if (currentWaste < waste) {
          waste = currentWaste;
          binIndex = j;
        }
        break;
      }
    }

    if (binIndex === -1) {
      bins.push(cableLength);
    } else {
      bins[binIndex] += cableLength;
    }
  }

  return bins.length;
}

export function toprailTubeSize() {
  // By default should be 20ft length.
  return 20;
}

function calculateNumberOfSnapCovers(toprailLengths: TopRailLengths, state: Project, type: String) {
  const aluminumLengths = toprailLengths.aluminum;

  let numberOfSnapCovers = 0;

  let postSpacing = state.settings.postSpacing;

  if (postSpacing === "custom") {
    postSpacing = state.settings.customPostSpacing;
  }

  postSpacing = parseFloat(postSpacing);

  // 5ft lengths is the max for snap covers.
  if ( postSpacing > 5 ) {
    postSpacing = 5;
  }

  const lengths = aluminumLengths[type];

  if ( lengths && lengths.length > 0 ) {
    lengths.forEach((length) => {
      const numberOfSections = Math.ceil(length / postSpacing);

      numberOfSnapCovers += numberOfSections;
    });
  }

  return numberOfSnapCovers;
}

function addAluminumSnapCovers(itemList: ItemListType, inventory: AllInventoryTypes[], state: Project, toprailLengths: TopRailLengths, type: String) {
  const { settings } = state;
  const snapCoverUpcs = getAluminumSnapCoverUpcs();

  const counts = calculateNumberOfSnapCovers(toprailLengths, state, type);

  itemList = addItem(
    itemList,
    inventory,
    snapCoverUpcs[settings.aluminumColor],
    counts
  );

  return itemList;
}

function addCalculatedToprail(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  state: Project,
  lengths: TopRailLengths,
  toprailLengths: TopRailLengths
) {
  const { settings } = state;

  const toprailInstallKitUpcs = getToprailInstallKitUpcs();

  if (lengths["stainless-steel"]) {
    const toprailUpc = getStainlessSteelToprailUpcs();

    Object.entries(lengths["stainless-steel"]).forEach(([key, value]) => {
      const toprail = toprailUpc?.[key] || "";

      if (toprail && value !== "stainless-steel") {
        const numberOfToprailSections = value || 0;
        // SS toprail is by foot not each.
        const numberOfRails = numberOfToprailSections * toprailTubeSize(state);

        itemList = addItem(itemList, inventory, toprail, numberOfRails, "ft");

        if (numberOfToprailSections) {
          itemList = addItemForceQuantity(
            itemList,
            inventory,
            toprailInstallKitUpcs["stainless-steel"][key],
            1
          );
        }
      }
    });
  }

  const aluminumP2PStairsUpcs = getAluminumP2PStairsUpcs();

  if (lengths.aluminum && settings.postMaterial !== "customer-provided") {
    const toprailUpc = getAluminumToprailUpcs();

    let toprail = 0;

    Object.entries(lengths.aluminum).forEach(([key, value]) => {
      if (key === "rectangular" || key === "shaped") {
        toprail = toprailUpc[key][settings.aluminumColor];
        const numberOfToprailSections = value || 0;

        itemList = addItem(
          itemList,
          inventory,
          toprail,
          numberOfToprailSections
        );

        itemList = addAluminumSnapCovers(itemList, inventory, state, toprailLengths, key);
      }

      if (key === "alum-p2p-stairs" && value) {
        const toprailP2P = aluminumP2PStairsUpcs[settings.aluminumColor];

        value.forEach((length) => {
          // P2P adds an extra foot.
          let totalP2PLength = Math.ceil(length) + 1;

          let multiplier = 1;
          if (totalP2PLength > 21) {
            multiplier = Math.ceil(totalP2PLength / 20);
            totalP2PLength = Math.ceil( length / multiplier );          
          }
          const size = findMatchingP2PSize(totalP2PLength, toprailP2P);

          itemList = addItem(itemList, inventory, toprailP2P[size], multiplier);
        });
      }
    });
  }

  if (lengths.wood) {
    const toprailUpc = getWoodToprailUpcs();

    Object.entries(lengths.wood).forEach(([key, value]) => {
      if (key.startsWith("wood")) {
        const [_wood, size, type] = key.split("_"); // eslint-disable-line no-unused-vars

        const woodLengths = value;

        if (woodLengths) {
          woodLengths.forEach((length) => {
            const toprail = toprailUpc[size][type];

            const woodLength = findMatchingP2PSize(length, toprail);

            itemList = addItem(itemList, inventory, toprail[woodLength], 1);
          });
        }
      }

      if (key === "alum-p2p-stairs" && value) {
        const toprailP2P = aluminumP2PStairsUpcs[settings.aluminumColor];

        value.forEach((length) => {
          // P2P adds an extra foot.
          const totalP2PLength = Math.ceil(length) + 1;

          const size = findMatchingP2PSize(totalP2PLength, toprailP2P);

          itemList = addItem(itemList, inventory, toprailP2P[size], 1);
        });
      }
    });
  }

  const toprailP2PInlineUpcs = getAluminumP2PInlineUpcs();
  const toprailP2PStairsUpcs = getAluminumP2PStairsUpcs();

  state.runs.forEach((run) => {
    let numberOfFlatP2PSections = 0;
    let numberOfStairsP2PSections = 0;

    const runSettings = getRunSettings(run, state.settings);

    let length = runSettings.get("postSpacing");

    if (length === "custom") {
      length = runSettings.get("customPostSpacing");
    } else {
      length = parseInt(length, 10);
    }

    if (
      runSettings.toprailMaterial === "aluminum" &&
      runSettings.aluminumToprailType === "alum-p2p"
    ) {
      const thePosts = getPosts(run, settings, state);
      const postsForRun = postsForTheRun(thePosts, run);

      numberOfFlatP2PSections +=
        (postsForRun.intermediate || 0) +
        ((postsForRun.stairPostTransition || 1) - 1) +
        (postsForRun.terminal ? 1 : 0);
    }

    if (
      runSettings.toprailMaterial === "wood" &&
      runSettings.woodToprailSetup === "wood-alum-p2p"
    ) {
      const thePosts = getPosts(run, settings, state);
      const postsForRun = postsForTheRun(thePosts, run);

      numberOfFlatP2PSections +=
        (postsForRun.intermediate || 0) +
        ((postsForRun.stairPostTransition || 1) - 1) +
        (postsForRun.terminal ? 1 : 0);
      
      if ( ! postsForRun.stairPostTerminal && ! postsForRun.terminal ) {
        numberOfFlatP2PSections += 1;
      }

      if (runSettings.woodAlumP2PStairsSetup === "in-between") {
        numberOfStairsP2PSections +=
          (postsForRun.stairPostIntermediate || 0) +
          (postsForRun.stairPostTerminal ? 1 : 0);
      }
    }

    const toprailP2P = toprailP2PInlineUpcs[settings.aluminumColor];

    const size = findMatchingP2PSize(length, toprailP2P);

    itemList = addItem(
      itemList,
      inventory,
      toprailP2P[size],
      numberOfFlatP2PSections
    );

    const toprailP2PStairs = toprailP2PStairsUpcs[settings.aluminumColor];

    const sizeStairs = findMatchingP2PSize(length, toprailP2PStairs);

    itemList = addItem(
      itemList,
      inventory,
      toprailP2PStairs[sizeStairs],
      numberOfStairsP2PSections
    );
  });

  return itemList;
}

export function sumGroup(group) {
  return group.reduce((sum, length) => sum + length, 0);
}

export function toprailLength(length = 20) {
  return length;
}

function hasWoodToprail(settings: ProjectSettings) {
  return (
    (settings.toprailMaterial === "aluminum" &&
      settings.aluminumToprailType === "wood-p2p") ||
    settings.toprailMaterial === "wood"
  );
}

function addWoodSealer(itemList, inventory) {
  itemList = addItemForceQuantity(itemList, inventory, getWoodSealerUpc(), 1);

  return itemList;
}

function addGalvanicScrewProtection(
  itemList: ItemListType,
  inventory: AllInventoryTypes[]
) {
  itemList = addItemForceQuantity(
    itemList,
    inventory,
    getGalvanicScrewProtectionUpc(),
    1
  );

  return itemList;
}

function isFittingWasherUpc(upc: string) {
  upc = upc.toString();
  const upcs = {
    616320730307: true,
    // WASHER FOR 3/16" FITTINGS
    616320730314: true,
  };

  return upcs[upc as keyof typeof upcs];
}

export function removeItem(itemList: ItemListType, upc: string) {
  upc = upc.toString();
  if (itemList[upc]) {
    delete itemList[upc];
  }

  return itemList;
}

export function getAluminumPostPricePerInch(inventory) {
  let item = findByUpc(616453984981, inventory);

  if (item) {
    item = mapQBObjectToJS(item);
  }

  let terminalItem = findByUpc(616453984998, inventory);

  if (terminalItem) {
    terminalItem = mapQBObjectToJS(terminalItem);
  }

  return {
    terminal: roundToHundreth(terminalItem.price / 42),
    intermediate: roundToHundreth(item.price / 42),
  };
}

export function addWoodCustomPosts(
  itemList,
  inventory,
  upc,
  quantity,
  measure,
  inchesPerPost
) {
  if (!itemList[upc]) {
    itemList = addItem(itemList, inventory, upc, quantity, measure);
    if (itemList[upc]) {
      itemList[upc].quantity = 0;
    }
  }

  if (itemList[upc]) {
    if (itemList[upc].counts && itemList[upc].counts[inchesPerPost]) {
      itemList[upc].counts[inchesPerPost] += quantity;
    } else {
      if (itemList[upc].counts) {
        itemList[upc].counts[inchesPerPost] = quantity;
      } else {
        itemList[upc].counts = {};
        itemList[upc].counts[inchesPerPost] = quantity;
      }
    }

    const item = woodCustomPostDescriptions(upc, itemList[upc]);

    itemList[upc].description = item.description;
    itemList[upc].price = item.price;
    itemList[upc].quantity = (itemList[upc].quantity || 0) + quantity;
    itemList[upc].total = roundToHundreth(item.price * itemList[upc].quantity);
  }

  return itemList;
}

function woodCustomPostDescriptions(upc: string, item: Item) {
  upc = upc.toString();
  let cost = 0;
  let desc = "";

  if (!item || !item.counts) {
    return { price: 0, description: item.description || "" };
  }

  switch (upc.toString()) {
    // 4x4 Batu Intermediate/Terminal
    case "662187019215":
      // Original description:
      //       BALAU (MAHOGANY) 3.5" x 3.5" x 120" (Net Size)
      // TERMINAL OR INTERMEDIATE POST
      // FOR 36"-42" FINISHED RAILING HEIGHT
      // UNDRILLED (D0)
      // E4E S4S
      // Architectural Grade Engineered Balau
      cost = item.price!;

      desc = Object.entries(item.counts)
        .map(([inches, quantity], index) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(
            parseFloat(inches)
          );

          return (
            (index === 0
              ? `BALAU (MAHOGANY) 3.5" x 3.5" x 120" (Net Size)
TERMINAL OR INTERMEDIATE POST
FOR 36"-42" FINISHED RAILING HEIGHT
UNDRILLED (D0)
E4E S4S
Architectural Grade Engineered Balau`
              : "") +
            `\n${quantity} EA AT ${inches} INCHES.
${holes} HOLES DRILLED PER CUT LENGTH.`
          );
        })
        .join("\n");
      return { price: cost, description: desc };
    default:
      return { price: 0, description: desc };
  }
}

export function addStainlessSteelCustomPosts(
  itemList,
  inventory,
  upc,
  quantity,
  measure,
  inchesPerPost,
  settings,
  type
) {
  if (!itemList[upc]) {
    itemList = addItem(itemList, inventory, upc, quantity, measure);

    if (itemList[upc]) {
      itemList[upc].quantity = 0;
    }
  }

  // Add extra length to posts for Fascia and Core mounts.
  if ( settings.stainlessPostShape === '2507-square' && (settings.mountStyle === 'fascia' || settings.mountStyle === 'core' ) ) {
    inchesPerPost += 8;
  }

  if ( settings.stainlessPostShape === '2507-square' && settings.mountStyle === 'deck' ) {
    // Add neoprene base pad.
    const neoprenePadUpc = getBasePadUpcs();

    itemList = addItem(itemList, inventory, neoprenePadUpc, quantity, 'ea');

    // Add orings to custom post.
    const oringUpcs = getOringUpcs();
    const oringLargeUpc = oringUpcs["large"];

    itemList = addItem(itemList, inventory, oringLargeUpc, quantity, 'ea');

    const oringSmallUpc = oringUpcs["small"];

    itemList = addItem(itemList, inventory, oringSmallUpc, quantity, 'ea');
  }

  if ( settings.stainlessPostShape === '2507-square' && settings.mountStyle === 'pony-wall' ) {
    const flangeUpcs = getStainlessFlangeUpcs();

    const flangeUpc = flangeUpcs[settings.stainlessPostShape][type];

    itemList = addItem(itemList, inventory, flangeUpc, quantity, 'ea');

    // Add neoprene base pad.
    const neoprenePadUpc = getBasePadUpcs();

    itemList = addItem(itemList, inventory, neoprenePadUpc, quantity, 'ea');

    // Add orings to custom post.
    const oringUpcs = getOringUpcs();
    const oringLargeUpc = oringUpcs["large"];

    itemList = addItem(itemList, inventory, oringLargeUpc, quantity, 'ea');

    const oringSmallUpc = oringUpcs["small"];

    itemList = addItem(itemList, inventory, oringSmallUpc, quantity, 'ea');

    // Mitrecutting service.
    const mitreCuttingServices = ponyWallMitreCutServices();

    itemList = addItem(itemList, inventory, mitreCuttingServices['stainless-steel-2507'], quantity, 'ea');
  }

  if ( settings.stainlessPostShape === '2507-square' ) {
    // Hole drilling service.
    const holeDrillingService = getGenericHoleDrillingService();

    const holes = calculateNumberOfSegmentsForCustomRailHeight(inchesPerPost);

    itemList = addItem(itemList, inventory, holeDrillingService, quantity * holes, 'ea');
  }
  

  if (itemList[upc]) {
    if (itemList[upc].counts) {
      if ( itemList[upc].counts[inchesPerPost] ) {
        itemList[upc].counts[inchesPerPost] += quantity;
      } else {
        itemList[upc].counts[inchesPerPost] = quantity;
      }
    } else {
      itemList[upc].counts = {};
      itemList[upc].counts[inchesPerPost] = quantity;
    }
  }

  if (itemList[upc]) {
    const item = stainlessSteelCustomPostDescriptions(upc, itemList[upc]);

    itemList[upc].description = item.description;
    itemList[upc].price = item.price;
    itemList[upc].quantity =
      Object.entries(itemList[upc].counts).reduce(
        (acc, [inches, count]) => acc + count * inches,
        0
      );

    itemList[upc].total = roundToHundreth(item.price * itemList[upc].quantity);
  }

  return itemList;
}

function stainlessSteelCustomPostDescriptions(upc: string, item: Item) {
  upc = upc.toString();
  let cost = 0;
  let desc = "";

  if (!item || !item.counts) {
    return { price: 0, description: item.description || "" };
  }

  switch (upc.toString()) {
    // SS 2507 Intermediate
    case "691027726488":
      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `ST MARTIN 2507 - CUSTOM HEIGHT INTERMEDIATE POST
            1ea UnDrilled 2507 Super Duplex Stainless Steel Square Intermediate Post INCLUDED - LENGTH IN INCHES: ${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
              inches * 6.00
            )} PER CUT LENGTH.
            ${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 6.00;
      }, 0);
      return { price: cost, description: desc };
    // SSQ 2507 Terminal
    case "691027725061":
      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `ST MARTIN 2507 - CUSTOM HEIGHT TERMINAL POST
            1ea UnDrilled 2507 Super Duplex Stainless Steel Square Terminal Post INCLUDED - LENGTH IN INCHES: ${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
              inches * 6.00
            )} PER CUT LENGTH.
            ${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 6.00;
      }, 0);
      return { price: cost, description: desc };
    // SSQ Terminal
    case "616453988231":
      // Original description:
      //       PIECES OF 2INCH STAINLESS STEEL SQUARE TERMINAL TUBE CUT TO CUSTOM LENGTHS: ___EA AT ______ INCHES FOR $__________PER CUT LENGTH.
      // ________# OF HOLES DRILLED PER CUT LENGTH.
      //      *****attach fabrication sheet*****
      // NOTE TO ESTIMATOR:
      // CALCULATE PRICE OF POST AND UPDATE PRICE ON ESTIMATE:
      // MULTIPLY # OF INCHES BY $8.75 (INCLUDES MITRE CUT, WELDING, POLISHING)
      // >>> DON"T FORGET TO ADD:
      // SVC-CustomFab-SS FOR EACH POST
      // >>> DELETE THIS NOTE FROM SO/INVOICE ONCE PRICE HAS BEEN UPDATED
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 8.75;
      }, 0);

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `PIECES OF 2INCH STAINLESS STEEL SQUARE TERMINAL TUBE CUT TO CUSTOM LENGTHS: ${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
            inches * 8.75
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    // SSQ Intermediate
    case "662187012087":
      // Original description:
      //       PIECES OF 2INCH STAINLESS STEEL SQUARE INTERMEDIATE TUBE CUT TO CUSTOM LENGTHS: ___EA AT ______ INCHES FOR $__________PER CUT LENGTH.
      // ________# OF HOLES DRILLED PER CUT LENGTH.
      //      *****attach fabrication sheet*****
      // NOTE TO ESTIMATOR:
      // CALCULATE PRICE OF POST AND UPDATE PRICE ON ESTIMATE:
      // MULTIPLY # OF INCHES BY $8.30 (INCLUDES MITRE CUT, WELDING, POLISHING)
      // >>> DON"T FORGET TO ADD:
      // SVC-CustomFab-SS FOR EACH POST
      // >>> DELETE THIS NOTE FROM SO/INVOICE ONCE PRICE HAS BEEN UPDATED
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 8.3;
      }, 0);

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `PIECES OF 2INCH STAINLESS STEEL SQUARE INTERMEDIATE TUBE CUT TO CUSTOM LENGTHS: ${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
            inches * 8.3
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    // SSR Terminal
    case "662187012094":
      // Original description:
      //       PIECES OF 2INCH STAINLESS STEEL ROUND TERMINAL TUBE CUT TO CUSTOM LENGTHS: ___EA AT ______ INCHES FOR $__________PER CUT LENGTH.
      // ________# OF HOLES DRILLED PER CUT LENGTH.
      //      *****attach fabrication sheet*****
      // NOTE TO ESTIMATOR:
      // CALCULATE PRICE OF POST AND UPDATE PRICE ON ESTIMATE:
      // MULTIPLY # OF INCHES BY $8.20 (INCLUDES MITRE CUT, WELDING, POLISHING)
      // >>> DON"T FORGET TO ADD:
      // SVC-CustomFab-SS FOR EACH POST
      // >>> DELETE THIS NOTE FROM SO/INVOICE ONCE PRICE HAS BEEN UPDATED
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 8.2;
      }, 0);

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `PIECES OF 2INCH STAINLESS STEEL ROUND TERMINAL TUBE CUT TO CUSTOM LENGTHS: ${quantity} EA AT ${inches} INCHES FOR $${roundToHundreth(
            inches * 8.2
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    // SSR Intermediate
    case "616453988576":
      // Original description:
      //       PIECES OF 2INCH STAINLESS STEEL ROUND INTERMEDIATE TUBE CUT TO CUSTOM LENGTHS: ___EA AT ______ INCHES FOR $__________PER CUT LENGTH.
      // ________# OF HOLES DRILLED PER CUT LENGTH.
      //      *****attach fabrication sheet*****
      // NOTE TO ESTIMATOR:
      // CALCULATE PRICE OF POST AND UPDATE PRICE ON ESTIMATE:
      // MULTIPLY # OF INCHES BY $7.75 (INCLUDES MITRE CUT, WELDING, POLISHING)
      // >>> DON"T FORGET TO ADD:
      // SVC-CustomFab-SS FOR EACH POST
      // >>> DELETE THIS NOTE FROM SO/INVOICE ONCE PRICE HAS BEEN UPDATED
      cost = Object.entries(item.counts).reduce((acc, [inches, quantity]) => {
        return 7.75;
      }, 0);

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(inches);

          return `PIECES OF 2INCH STAINLESS STEEL ROUND INTERMEDIATE TUBE CUT TO CUSTOM LENGTHS: ${quantity} EA AT ${inches} INCHES FOR $${roundToHundreth(
            inches * 7.75
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    default:
      return { price: 0, description: desc };
  }
}

export function addItem(
  itemList: Record<string, Item>,
  inventory: AllInventoryTypes[],
  upc: string,
  quantity: number,
  measure = "ea"
) {
  if (!upc) {
    return itemList;
  }

  upc = upc.toString();

  if (quantity === 0) {
    if (itemList[upc] && itemList[upc].quantity === 0) {
      delete itemList[upc];
    }

    return itemList;
  }

  if (!quantity) {
    return itemList;
  }

  let item = findByUpc(upc, inventory);

  if (item) {
    const mappedItem = mapQBObjectToJS(item);

    if (upc === ponyWallPowderCoatingServices().aluminum.black) {
      mappedItem.price = 19.95;
    }

    if (upc === ponyWallPowderCoatingServices().aluminum["anodized-black"]) {
      mappedItem.price = 19.95;
    }

    if (!itemList[upc]) {
      itemList[upc] = {
        quantity: quantity,
        measure: measure,
        upc: upc,
        description: mappedItem.description,
        name: mappedItem.name,
        type: mappedItem.type || undefined,
        price: mappedItem.price || 0,
        total: roundToHundreth((mappedItem.price || 0) * quantity),
      };
    } else {
      itemList[upc].quantity = itemList[upc].quantity + quantity;
      if (mappedItem.price) {
        itemList[upc].total = roundToHundreth(
          itemList[upc].quantity * mappedItem.price
        );
      }
    }
  }

  return itemList;
}

export function addCustomPart(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  upc: string,
  quantity: number,
  description: string,
  price = 0,
  measure = "ea"
) {
  upc = upc.toString();
  let item = findByUpc(upc, inventory);

  if (item) {
    const itemMapped = mapQBObjectToJS(item);

    if (!itemList[upc]) {
      itemList[upc] = {
        quantity: quantity,
        measure: measure,
        upc: upc,
        description: description,
        name: itemMapped.name,
        type: itemMapped.type || undefined,
        price: parseFloat(price || itemMapped.price || 0),
        total: roundToHundreth((price || itemMapped.price || 0) * quantity),
      };
    } else {
      itemList[upc].quantity = itemList[upc].quantity + quantity;
      itemList[upc].total = roundToHundreth(
        itemList[upc].quantity * (itemList[upc].price || 0)
      );
    }
  }

  return itemList;
}

export function addItemForceQuantity(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  upc: string | number,
  quantity: number,
  measure = "ea"
) {
  upc = upc.toString();
  if (quantity === 0) {
    return itemList;
  }

  let item = findByUpc(upc, inventory);

  if (item) {
    const itemMapped = mapQBObjectToJS(item);

    if (!itemList[upc]) {
      itemList[upc] = {
        quantity: quantity,
        measure: measure,
        upc: upc,
        description: itemMapped.description,
        name: itemMapped.name,
        price: itemMapped.price,
        type: itemMapped.type || undefined,
        total: roundToHundreth((itemMapped.price || 0) * quantity),
      };
    } else {
      itemList[upc].quantity = quantity;
      itemList[upc].total = roundToHundreth(quantity * (itemMapped.price || 0));
    }
  }

  return itemList;
}

export function addShrinkTube(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  postsForRun: Record<string, number>,
  settings: ProjectSettings
) {
  const numCables = getNumberOfCableRuns(settings);

  // Shrink tubing stairs.
  const shrinkTubeUpcs = getShrinkTubeUpcs();

  const totalShrinkIntermediate = Object.entries(postsForRun).reduce(
    (count, [type, posts]) => {
      if (type === "stairPostIntermediate") {
        return count + posts;
      }

      return count;
    },
    0
  );

  const totalShrinkTerminal = Object.entries(postsForRun).reduce(
    (count, [type, posts]) => {
      if (type === "stairPostTransition" || type === "stairPostTerminal") {
        return count + posts;
      }

      return count;
    },
    0
  );

  if (totalShrinkIntermediate > 0) {
    // 4 inches per unit and is measured by inch.
    const quantity = totalShrinkIntermediate * numCables * 4;

    itemList = addItem(
      itemList,
      inventory,
      shrinkTubeUpcs.intermediate,
      quantity,
      "in"
    );
  }

  if (totalShrinkTerminal > 0) {
    // 4 inches per unit and is measured by inch.
    const quantity = totalShrinkTerminal * numCables * 4;

    itemList = addItem(
      itemList,
      inventory,
      shrinkTubeUpcs.terminal,
      quantity,
      "in"
    );
  }

  return itemList;
}

export function addGrommets(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  // Grommets only use if 1/8th inch cable is selected.
  if (
    settings.cableSize === "1 x 19 - 1/8”" &&
    settings.postMaterial === "aluminum"
  ) {
    const grommetUpcs = getGrommetUpcs();

    const numCables = getNumberOfCableRuns(settings);

    const totalIntermediate = getTotalIntermediatePosts(postsForRun);

    const totalTerminal = getTotalTerminalPosts(postsForRun);

    const quantityGrommetsIntermediate = Math.ceil(
      (totalIntermediate || 0) * numCables
    );

    itemList = addItem(
      itemList,
      inventory,
      grommetUpcs.intermediate.toString(),
      quantityGrommetsIntermediate
    );

    const quantityGrommetsTerminal = Math.ceil(
      (totalTerminal || 0) * numCables
    );

    itemList = addItem(
      itemList,
      inventory,
      grommetUpcs.terminal.toString(),
      quantityGrommetsTerminal
    );
  }

  return itemList;
}

export function addAluminumBasePlatesForWoodToprail(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  postsForRun: Record<string, number>
) {
  const basePlateUpcs = getAluminumBasePlateForWoodToprailUpcs();
  const totalPostCount = totalPosts(postsForRun);

  const { woodToprailSize, aluminumColor } = settings;

  const basePlateUpc =
    basePlateUpcs[woodToprailSize as keyof typeof basePlateUpcs];
  const assemblyUpc =
    basePlateUpc[aluminumColor as keyof typeof basePlateUpc]?.toString();

  if (assemblyUpc) {
    itemList = addItem(itemList, inventory, assemblyUpc, totalPostCount);
  }

  return itemList;
}

export function addStainlessSteelSplices(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  distance: number
) {
  const numberOfSplices = getNumberOfToprailSplices(distance);

  if (numberOfSplices > 0) {
    const spliceUpcs = getStainlessSteelTopRailSpliceUpcs();

    const spliceUpc =
      spliceUpcs[
        settings.stainlessSteelToprailType as keyof typeof spliceUpcs
      ].toString();

    itemList = addItem(itemList, inventory, spliceUpc, numberOfSplices);
  }

  return itemList;
}

export function addRampPostServices(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  run: Run,
  state: Project
) {
  if (!run.stairs || !(run.stairs as any).continuousStairs) {
    return itemList;
  }

  if (settings.mountStyle === "fascia") {
    return itemList;
  }

  const posts = getPosts(run, settings, state);

  const ramps = (run.stairs as any).continuousStairs.filter(
    (stair: Stairs) => stair.isRamp
  );

  if (!ramps.size) {
    return itemList;
  }

  let numberOfMitredPosts = 0;

  ramps.forEach((ramp: any) => {
    const rampPosts = posts.filter(
      (post) =>
        (post.matchingStair as any).includes(ramp.id) &&
        (post.type === "stairPostIntermediate" ||
          post.type === "stairPostTerminal")
    );

    numberOfMitredPosts += rampPosts.length;
  });

  if (numberOfMitredPosts > 0) {
    let mitredPostUpc: string | undefined;

    if (settings.postMaterial === "aluminum") {
      mitredPostUpc =
        ponyWallMitreCutServices()[settings.postMaterial]["stairs"];
    }

    if (settings.mountStyle !== 'core' && settings.postMaterial === "stainless-steel") {
      mitredPostUpc = ponyWallMitreCutServices()[settings.postMaterial];

      if (settings.stainlessPostShape === "2507-square" && settings.mountStyle !== "pony-wall") {
        mitredPostUpc = ponyWallMitreCutServices()['stainless-steel-2507'];
      }
    }

    if (mitredPostUpc) {
      itemList = addItem(
        itemList,
        inventory,
        mitredPostUpc,
        numberOfMitredPosts
      );
    }
  }

  return itemList;
}

export function addStainlessSteelSplicesForStairs(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  run: Run,
  settings: ProjectSettings,
  state: Project
) {
  if (!run.stairs && !(run.stairs as any).continuousStairs) {
    return itemList;
  }

  const posts = getPosts(run, state.settings, state);

  const groups = getGroupedPostsForRun(posts, run);

  let numberOfSplices = 0;

  Object.entries(groups).forEach(([key, value]) => {
    if (value.length < 2) {
      return;
    }

    const { distance } = getDistancesFromPostGroupsForRun(
      run,
      state.stairs,
      value,
      key
    );

    numberOfSplices += getNumberOfToprailSplices(distanceToFeet(distance));
  });

  if (numberOfSplices > 0) {
    const spliceUpcs = getStainlessSteelTopRailSpliceUpcs();

    const spliceUpc =
      spliceUpcs[
        settings.stainlessSteelToprailType as keyof typeof spliceUpcs
      ].toString();

    itemList = addItem(itemList, inventory, spliceUpc, numberOfSplices);
  }

  return itemList;
}

function getTotalIntermediatePosts(postsForRun: Record<string, number>) {
  return Object.entries(postsForRun).reduce((count, [type, posts]) => {
    if (type === "intermediate") {
      return count + posts;
    }

    return count;
  }, 0);
}

function getTotalTerminalPosts(postsForRun: Record<string, number>) {
  return Object.entries(postsForRun).reduce((count, [type, posts]) => {
    if (type === "terminal") {
      return count + posts;
    }

    return count;
  }, 0);
}

const calculateNumberOfEndCaps = (runs, corners, settings) => {
  return runs.reduce(
    (endCaps, run) => {
      const runSettings = getRunSettings(run, settings);

      if (runSettings.toprailMaterial === "stainless-steel") {
        endCaps["stainless-steel"][runSettings.stainlessSteelToprailType] +=
          calculateNumberOfEndCapsPerRun(run, corners);
      }

      if (runSettings.toprailMaterial === "aluminum") {
        endCaps["aluminum"][runSettings.aluminumToprailType] +=
          calculateNumberOfEndCapsPerRun(run, corners);
      }

      return endCaps;
    },
    {
      aluminum: { "alum-p2p": 0, shaped: 0, rectangular: 0 },
      "stainless-steel": { round: 0, flat: 0, "2507": 0 },
    }
  );
};

const calculateNumberOfEndCapsPerRun = (run: Run, corners) => {
  let endcaps = 2;

  corners.forEach((corner) => {
    // If the corner is on this run remove an endcap.
    if (corner.runs.includes(run.id) && endcaps !== 0) {
      endcaps = endcaps - 1;
    }
  });

  return endcaps;
};

export const isRightAngleCorner = (corner: Corner) => {
  const { angle } = corner;

  if (
    (angle < 91 && angle > 89) ||
    (angle > -91 && angle < -89) ||
    (angle < 271 && angle > 269) ||
    (angle > -271 && angle < -269)
  ) {
    return true;
  }

  return false;
};

function addAluminumFasciaBrackets( itemList, inventory, corners, state ) {
  if ( state.settings.mountStyle !== 'fascia' || state.settings.postMaterial !== 'aluminum' ) {
    return itemList;
  }
  
  const fasciaBracketParts = getAluminumFasciaBracketParts();
  
  // Add inline parts first for intermediate posts.
  if ( state.runs.size > 0 ) {
    state.runs.forEach((run) => {
      const runSettings = getRunSettings(run, state.settings);
      const posts = getPosts(run, runSettings, state);
      const postsForRun = postsForTheRun(posts, run);
      
      let inlinePosts = (postsForRun.intermediate || 0) +
      (postsForRun.stairPostIntermediate || 0) +
      (postsForRun.stairPostTransition || 0) +
      (postsForRun.stairPostTerminal || 0) +
      (postsForRun.terminal || 0);
      
      if ( runHasCorners ( run, corners ) ) {
        corners.forEach((corner) => {
          const isSingleCorner =
            state.corners.find((val) => {
              return (
                val.type === "single" &&
                arrayEquals(
                  Object.keys(val.id.toJS()).sort(),
                  corner.runs.toJS().sort()
                )
              );
            }) !== undefined;

          if (corner.points[run.id]) {
            const totalTerminal = ((postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0 ));
            // Terminal/intermediate posts are not inline when on a corner.
            if ( isSingleCorner && totalTerminal === 2 ) {
              inlinePosts -= 1;
            } else if ( !isSingleCorner ) {
              inlinePosts -= 1;
            }
          }
        });
      }
      
      const items = fasciaBracketParts[runSettings.aluminumColor][ 'inline' ];

      if ( items && inlinePosts > 0 ) {
        Object.entries(items).forEach(([type, item]) => {
          if ( runSettings.fasciaBracketType === "alum-4hole-bracket" && type === "shim" ) {
            // Do not add shims if option is without shims.
            return;
          }
          itemList = addItem(itemList, inventory, item.upc, inlinePosts * item.count);
        });
      }
    });
  }

  if (corners.length > 0) {
    corners.forEach((corner) => {
      
      const isSingleCorner =
        state.corners.find((val) => {
          return (
            val.type === "single" &&
            arrayEquals(
              Object.keys(val.id.toJS()).sort(),
              corner.runs.toJS().sort()
            )
          );
        }) !== undefined;

      if ( isSingleCorner ) {
        let insideCorner = false;

        insideCorner =
            state.corners.find((val) => {
              return (
                val.insideCorner === true &&
                arrayEquals(
                  Object.keys(val.id.toJS()).sort(),
                  corner.runs.toJS().sort()
                )
              );
            }) !== undefined;

        if ( insideCorner ) {
          const items = fasciaBracketParts[state.settings.aluminumColor]['insideSingleCorner'];

          if ( items ) {
            Object.entries(items).forEach(([type, item]) => {
              if ( state.settings.fasciaBracketType === "alum-4hole-bracket" && type === "shim" ) {
                // Do not add shims if option is without shims.
                return;
              }
              itemList = addItem(itemList, inventory, item.upc, item.count);
            });
          }
        } else {
          const items = fasciaBracketParts[state.settings.aluminumColor]['outsideSingleCorner'];

          if ( items ) {
            Object.entries(items).forEach(([type, item]) => {
              if ( state.settings.fasciaBracketType === "alum-4hole-bracket" && type === "shim" ) {
                // Do not add shims if option is without shims.
                return;
              }
              itemList = addItem(itemList, inventory, item.upc, item.count);
            });
          }
        }
      } else {
        const items = fasciaBracketParts[state.settings.aluminumColor]['twoPostCorner'];

        let insideCorner = false;

        insideCorner =
            state.corners.find((val) => {
              return (
                val.insideCorner === true &&
                arrayEquals(
                  Object.keys(val.id.toJS()).sort(),
                  corner.runs.toJS().sort()
                )
              );
            }) !== undefined;

        if ( items && ! insideCorner ) {
          Object.entries(items).forEach(([type, item]) => {
            if ( state.settings.fasciaBracketType === "alum-4hole-bracket" && type === "shim" ) {
              // Do not add shims if option is without shims.
              return;
            }
            itemList = addItem(itemList, inventory, item.upc, item.count);
          });
        } else {
          const inlineItems = fasciaBracketParts[state.settings.aluminumColor]['inline'];

          Object.entries(inlineItems).forEach(([type, item]) => {
            if ( state.settings.fasciaBracketType === "alum-4hole-bracket" && type === "shim" ) {
              // Do not add shims if option is without shims.
              return;
            }
            itemList = addItem(itemList, inventory, item.upc, item.count * 2);
          });
        }
      }



    });
  }

  return itemList;
}

export { PostItemList };
